import { fetchCollection } from 'api/useCollectionData'

import { SortOrders } from 'enums/SortOrders'

import {
  ClinicalTrialsModel,
  MediaModel,
  PersonModel,
  PresentationsModel,
  PressReleasesModel,
  TweetsModel,
} from 'config/common/baseModel'
import { CardioCandidateModel } from 'config/common/baseModel/cardio360/Cardio360Candidate'

import {
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

export const ObesityCandidateRankingModel = compose<
  typeof CardioCandidateModel
>(
  whiteListColumn([
    'candidateName',
    'companies',
    'candidateType',
    'target',
    'manualLatestPhaseTableau',
    'routeOfAdministration',
    'lookupCandidatePublications',
  ]),
  renameColumn('companies', 'Companies'),
  renameColumn('candidateType', 'Candidate Type'),
  renameColumn('target', 'Target')
)({
  ...CardioCandidateModel,
  name: 'Candidates',
  defaultSortKey: 'candidateName',
  defaultSortOrder: SortOrders.ASC,
})

export const ObesityPublicationsModel = compose<typeof PresentationsModel>(
  whiteListColumn([
    'id',
    'title',
    'journalTitle',
    'publicationDate',
    'lookupCandidateList',
    'afArticleType',
    'afSentiment',
    'linkAfLeadAuthor',
    'linkAfLastAuthor',
    'articleLink',
  ])
)({
  ...PresentationsModel,
  name: 'Publications',
  endpoint: 'publication',
  defaultSortKey: 'publicationDate',
  defaultSortOrder: SortOrders.DESC,
})

export const ObesityApprovalsModel = compose<typeof PressReleasesModel>(
  whiteListColumn([
    'title',
    'datePublished',
    'lookupCandidateName',
    'lookupDrugTarget',
    'link',
  ])
)({
  ...PressReleasesModel,
  name: 'Approvals',
  customAPIFunction: (axios) => {
    const resolveData = Promise.all([
      fetchCollection(axios, 'obesity-360', PressReleasesModel.endpoint, {
        airtableBase: 'cardio360',
        view: 'Obesity Press Releases - Platform View - Regulatory',
      }),
      fetchCollection(axios, 'obesity-360', MediaModel.endpoint, {
        airtableBase: 'cardio360',
        view: 'Obesity Media - Platform View - Regulatory',
      }),
    ]).then((d) => d[0].results.concat(d[1].results))

    return () => resolveData
  },
})

export const ObesityTrialsModel = compose<typeof ClinicalTrialsModel>(
  whiteListColumn([
    'trialId',
    'title',
    'acronym',
    'lookupCandidateName',
    'sponsorOrgSync',
    'phasesClean',
    'status',
    'enrolledAt',
    'primaryCompletionDate',
    'primaryOutcomeMeasuresClean',
    'secondaryOutcomeMeasuresClean',
    'completedAt',
    'targetEnrollment',
    'ages',
    'locations',
    'url',
  ]),
  renameColumn('url', 'Link')
)({
  ...ClinicalTrialsModel,
  name: 'Clinical Trials',
  endpoint: 'clinical-trial',
  defaultSortKey: 'registeredAt',
  defaultSortOrder: SortOrders.DESC,
})

export const ObesityMediaModel = compose<typeof MediaModel>(
  whiteListColumn([
    'title',
    'datePublished',
    'lookupCandidateName',
    'target',
    'link',
  ]),
  renameColumn('lookupCandidateName', 'Candidate Name')
)({
  ...MediaModel,
  name: 'Media',
  defaultSortKey: 'datePublished',
  defaultSortOrder: SortOrders.DESC,
})

export const ObesityPressReleasesModel = whiteListColumn<
  typeof PressReleasesModel
>([
  'title',
  'datePublished',
  'lookupCandidateName',
  'lookupDrugTarget',
  'link',
])({
  ...PressReleasesModel,
  detailView: {
    link: 'link',
    abstract: 'description',
  },
})

export const ObesitySocialMediaModel = compose<typeof TweetsModel>(
  whiteListColumn([
    'createdAt',
    'fullText',
    'afSentiment',
    'candidateName',
    'targetFromCandidates',
    'screenName',
    'persons',
    'luHIndex',
    'followerCount',
    'retweetCount',
    'favoriteCount',
    'tweetUrl',
  ]),
  renameColumn('fullText', 'Post'),
  renameColumn('screenName', 'Account Name'),
  renameColumn('retweetCount', 'Reposts'),
  renameColumn('favoriteCount', 'Favorites'),
  renameColumn('tweetUrl', 'Link')
)({
  ...TweetsModel,
  name: 'Social Media',
  defaultSortKey: 'createdAt',
  defaultSortOrder: SortOrders.DESC,
})

export const ObesityKolsModel = compose<typeof PersonModel>(
  whiteListColumn([
    'afName',
    'affiliations',
    'afHIndex',
    'leadAuthorOfPublication',
    'lastAuthorOfPublication',
    'lookupFullTextFromTweets',
    'kolRankingScore',
  ]),
  updateColumnAttributes('lastAuthorOfPublication', {
    width: 300,
  }),
  updateColumnAttributes('leadAuthorOfPublication', {
    width: 300,
  }),
  updateColumnAttributes('affiliations', {
    width: 400,
  })
)({
  ...PersonModel,
  emptyThreshold: 100,
  defaultSortKey: 'kolRankingScore',
  defaultSortOrder: SortOrders.DESC,
})
