import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import AdministrationRoute from 'config/common/tagColours/AdministrationRoute'
import Formulation from 'config/common/tagColours/Formulation'
import LicensedIndication from 'config/common/tagColours/LicensedIndication'
import Phase from 'config/common/tagColours/Phase'
import ProductionType from 'config/common/tagColours/ProductionType'
import TargetPopulation from 'config/common/tagColours/TargetPopulation'
import TreatmentTiming from 'config/common/tagColours/TreatmentTiming'
import TreatmentType from 'config/common/tagColours/TreatmentType'
import VaccineType from 'config/common/tagColours/VaccineType'
import Valency from 'config/common/tagColours/Valency'

export type RespiratoryCandidatesData =
  | 'candidateId'
  | 'linkInfectiousDisease'
  | 'candidateName'
  | 'manualLatestPhase'
  | 'vaccineType'
  | 'productionType'
  | 'adjuvanted'
  | 'linkCompanies'
  | 'linkResearchInstitutions'
  | 'country'
  | 'linkTimelines'
  | 'linkApprovals'
  | 'administrationRoute'
  | 'numberOfDoses'
  | 'doseQuantity'
  | 'daysBetweenDoses'
  | 'treatmentType'
  | 'mechanismOfActionDescription'
  | 'clinicalTrials'
  | 'antibodyType'
  | 'licensedIndication'
  | 'formulations'
  | 'contraindications'
  | 'linkClinicalTrials'
  | 'linkPressReleases'
  | 'linkMedia'
  | 'linkPublications'
  | 'afTargetPopulation'
  | 'alternateNames'
  | 'influenzaValency'
  | 'treatmentTiming'
  | 'timelines'
  | 'vaccineGroupTypeSplit'
  | 'countryLu'
  | 'seasonalOrPandemic'

const allowedAirtables = [
  'front_end_export_influenza_vax',
  'front_end_export_rsv_vax',
  'front_end_export_influenza_treatments',
  'front_end_export_rsv_treatments',
  'combination vaccines RSV',
  'api_combination_vaccines_influenza',
  'front_end_export_influenza_vax_seasonal',
  'front_end_export_influenza_vax_pandemic',
] as const

export const RespiratoryCandidatesModel: IModel<
  Record<RespiratoryCandidatesData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  endpoint: 'candidate',
  name: 'Candidates',
  entityName: 'Candidates',
  searchField: 'candidateName',
  displayKey: 'candidateName',
  chartColumnBlackList: [
    'linkInfectiousDisease',
    'alternateNames',
    'adjuvanted',
    'linkCompanies',
    'linkResearchInstitutions',
    'linkTimelines',
    'administrationRoute',
    'numberOfDoses',
    'doseQuantity',
    'daysBetweenDoses',
    'clinicalTrials',
    'licensedIndication',
    'formulations',
    'afTargetPopulation',
  ],
  schema: {
    columns: [
      {
        key: 'countryLu',
        label: 'Country',
        width: 200,
        type: ValueType.SINGLE,
      },
      {
        key: 'candidateId',
        label: 'Candidate Id',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Disease',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateName',
        label: 'Name',
        width: 300,
        type: ValueType.SINGLE,
        sticky: 'left',
      },
      {
        key: 'manualLatestPhase',
        label: 'Phase',
        width: 170,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Phase,
        },
        sortOrderObject: [
          'III',
          'II/III',
          'II',
          'I/II',
          'I',
          'IV',
          'Approved',
          'Pre-clinical',
          'Discovery',
          'Planned/Announced',
          'Inactive',
          'Discontinued',
        ],
        graphType: 'Bar',
      },
      {
        key: 'vaccineType',
        label: 'Technology Type',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: VaccineType,
        },
        graphType: 'Pie',
      },
      {
        key: 'productionType',
        label: 'Production Type',
        width: 150,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: ProductionType,
        },
        graphType: 'Pie',
      },
      {
        key: 'adjuvanted',
        label: 'Adjuvanted',
        width: 130,
        type: ValueType.SINGLE,
      },
      {
        key: 'linkCompanies',
        label: 'Primary Developers',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'vaccineGroupTypeSplit',
        label: 'Technology Type',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkResearchInstitutions',
        label: 'Affiliated Institutions',
        width: 270,
        type: ValueType.SINGLE,
      },
      {
        key: 'country',
        label: 'Country',
        width: 200,
        type: ValueType.SINGLE,
        graphType: 'Pie',
      },
      {
        key: 'linkTimelines',
        label: 'Timelines',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkApprovals',
        label: 'Approvals',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'administrationRoute',
        label: 'Routes of Administration',
        width: 200,
        type: ValueType.MULTI,
        cellFormat: {
          colours: AdministrationRoute,
        },
      },
      {
        key: 'numberOfDoses',
        label: 'Number Of Doses',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'doseQuantity',
        label: 'Dose Quantity',
        width: 250,
        type: ValueType.TEXT,
      },
      {
        key: 'daysBetweenDoses',
        label: 'Days Between Doses',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'treatmentType',
        label: 'Technology Type',
        width: 240,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TreatmentType,
        },
        graphType: 'Bar',
      },
      {
        key: 'mechanismOfActionDescription',
        label: 'Mechanism Of Action',
        width: 280,
        type: ValueType.TEXT,
      },
      {
        key: 'clinicalTrials',
        label: 'Clinical Trials',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'antibodyType',
        label: 'Antibody Type',
        width: 160,
        type: ValueType.MULTI,
      },
      {
        key: 'licensedIndication',
        label: 'Licensed Indication',
        width: 250,
        type: ValueType.MULTI,
        cellFormat: {
          colours: LicensedIndication,
        },
      },
      {
        key: 'formulations',
        label: 'Formulations',
        width: 140,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Formulation,
        },
      },
      {
        key: 'contraindications',
        label: 'Contraindications',
        width: 300,
        type: ValueType.TEXT,
      },
      {
        key: 'linkClinicalTrials',
        label: 'Trials',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'linkPressReleases',
        label: 'Press Releases',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'linkMedia',
        label: 'Media',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'linkPublications',
        label: 'Publications',
        width: 300,
        type: ValueType.MULTI,
      },
      {
        key: 'afTargetPopulation',
        label: 'Target Population',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TargetPopulation,
        },
      },
      {
        key: 'alternateNames',
        label: 'Alternative Names',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'influenzaValency',
        label: 'Valency',
        width: 150,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Valency,
        },
      },
      {
        key: 'treatmentTiming',
        label: 'Stage Of Intervention',
        width: 150,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TreatmentTiming,
        },
        graphType: 'Pie',
      },
      {
        key: 'timelines',
        label: 'Timelines',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'seasonalOrPandemic',
        label: 'Seasonal or Pandemic',
        width: 200,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: {
            Seasonal: 'rgb(207, 223, 255)',
            Pandemic: 'rgb(255, 234, 182)',
          },
        },
      },
    ],
  },
}
