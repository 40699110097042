import { CountriesModel, DealsModel } from 'config/common/baseModel'

import {
  whiteListColumn,
  compose,
  renameColumn,
  updateColumnAttributes,
} from 'utils/overrideModel'

export const SupplyAndProductionProductionDealsModel = compose<
  typeof DealsModel
>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'organisations',
    'countries',
    'afExpectedProduction',
    'mediaUrls',
    'pressUrls',
    'afTypeOfProduction',
    'afExpectedProduction2022',
    'afDateExpectedProductionLastAnnounced',
    'afPublicExpectedProductionNotes',
  ]),
  renameColumn('countries', 'Country source of production')
)(DealsModel)
export const SupplyAndProductionDealsModel = compose<typeof DealsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'countries',
    'organisations',
    'afPriceDealType',
    'companyRegions',
    'afPricePerDoseVaccineUsd',
    'afPricePerVaccineTreatment',
    'notes',
    'mediaUrls',
    'pressUrls',
  ]),
  renameColumn('countries', 'Countries that have paid this price'),
  renameColumn('afPricePerDoseVaccineUsd', 'Price per dose (USD)'),
  renameColumn('companyRegions', 'Regions that have paid this price'),
  renameColumn('notes', 'Description'),
  updateColumnAttributes('countries', {
    width: 250,
  }),
  updateColumnAttributes('linkRegions', {
    width: 250,
  })
)(DealsModel)

export const DeliveriesCountryModel = compose<typeof CountriesModel>(
  whiteListColumn([
    'name',
    'population',
    'afEconomyIncomeBand',
    'afContinent',
    'fTotalDeliveries',
    'fDeliveriesToDateExcThoseDonated',
    'fBilateralDeliveryToDate',
    'fCovaxDeliveryToDate',
    'fDirectDonationDeliveryToDate',
  ]),
  renameColumn('name', 'Country'),
  renameColumn('afEconomyIncomeBand', 'Income Band')
)(CountriesModel)
