import GenericTable from 'modules/Tables'

import { IModel } from 'interfaces/model.interface'
import {
  INavigationPages,
  ISingleNavigationPage,
} from 'interfaces/navigationPage.interface'

import {
  VaccinesDealsAndDonationsDealsModel,
  VaccinesProductionDealsModel,
  VaccinesSupplyModel,
} from '../../Science360/Vaccines/VaccinesModels'
import {
  SupplyAndProductionProductionDealsModel,
  SupplyAndProductionDealsModel,
} from './Covid19CommercialVaccineModels'
import { Covid19CommercialVaccinesSlugs } from './Covid19CommercialVaccinesMenu'

const DealsSupplyAndDonations: Covid19CommercialVaccinesPage<
  typeof VaccinesDealsAndDonationsDealsModel
> = {
  key: 'DealsSupplyAndDonations',
  path: ['deals', 'supply-by-deal'],
  model: { ...VaccinesDealsAndDonationsDealsModel, name: 'Supply By Deal' },
  component: GenericTable,
  views: [
    {
      name: 'All',
      airtableName: 'api_supply_all',
    },
    {
      name: 'Bilateral Deals',
      airtableName: 'api_supply_all_bilateral_deals',
    },
    {
      name: 'Bilateral Donations',
      airtableName: 'api_supply_all_bilateral_donations',
    },
    {
      name: 'COVAX Deliveries',
      airtableName: 'api_supply_all_covax_deliveries',
    },
  ],
}

type Covid19CommercialVaccinesPage<TModel extends IModel<any>> =
  ISingleNavigationPage<Covid19CommercialVaccinesSlugs, TModel>

const DealsProduction: Covid19CommercialVaccinesPage<
  typeof SupplyAndProductionProductionDealsModel
> = {
  key: 'DealsProduction',
  path: ['production', 'company-stated-production'],
  model: {
    ...VaccinesProductionDealsModel,
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_production_deals',
    },
  ],
}

const DealsPrice: Covid19CommercialVaccinesPage<
  typeof SupplyAndProductionDealsModel
> = {
  key: 'DealsPrice',
  path: null,
  model: {
    ...SupplyAndProductionDealsModel,
    name: 'Data',
    excludeGeneratedCharts: true,
    hideTableHeader: true,
  },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_price_per_vaccine_treatment',
    },
  ],
}
export const DealsPricePages: INavigationPages<Covid19CommercialVaccinesSlugs> =
  {
    DealsPrice,
  }

const SupplyByCountry: Covid19CommercialVaccinesPage<
  typeof VaccinesSupplyModel
> = {
  key: 'Supply',
  path: ['deliveries', 'by-country'],
  model: { ...VaccinesSupplyModel, name: 'Deliveries By Country' },
  component: GenericTable,
  views: [
    {
      name: 'Default',
      airtableName: 'api_supply_per_country',
    },
  ],
}

export const Covid19CommercialVaccinesPages: INavigationPages<Covid19CommercialVaccinesSlugs> =
  {
    DealsSupplyAndDonations,
    DealsProduction,
    DealsPrice,
    SupplyByCountry,
  }
