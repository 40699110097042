import InfluenzaDetailView from 'routes/apps/influenza/Candidates'

import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import {
  ConferencePresentationsModel,
  MediaModel,
  PressReleasesModel,
} from 'config/common/baseModel'
import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { ClinicalTrialsModel } from 'config/common/baseModel/ClinicalTrials'
import { PresentationsModel } from 'config/common/baseModel/Presentations'
import { RespiratoryCandidatesModel } from 'config/common/baseModel/respiratory360/RespiratoryCandidates'
import { RespiratoryResultsModel } from 'config/common/baseModel/respiratory360/RespiratoryResults'

import {
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

export const InfluenzaCandidatesVaccineModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'linkInfectiousDisease',
    'candidateName',
    'alternateNames',
    'manualLatestPhase',
    'vaccineType',
    'adjuvanted',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
    'seasonalOrPandemic',
  ]),
  updateColumnAttributes('manualLatestPhase', {
    sortOrderObject: [
      'Approved',
      'Phase III',
      'Phase II/III',
      'Phase II',
      'Phase I/II',
      'Phase I',
      'Preclinical',
      'Inactive',
      'Discontinued',
    ],
  }),
  updateColumnAttributes('candidateName', {
    type: ValueType.MULTI,
  })
)({
  ...RespiratoryCandidatesModel,
  name: 'Vaccine Candidates',
  endpoint: 'vaccine-candidate',
  renderDetailView: InfluenzaDetailView,
  defaultSortOrder: SortOrders.ASC,
  defaultSortKey: 'manualLatestPhase',
})

export const InfluenzaCandidatesNonVaccinesModels = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'linkInfectiousDisease',
    'candidateName',
    'alternateNames',
    'manualLatestPhase',
    'treatmentTiming',
    'treatmentType',
    'mechanismOfActionDescription',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
  ]),
  updateColumnAttributes('manualLatestPhase', {
    sortOrderObject: [
      'Approved',
      'Phase III',
      'Phase II/III',
      'Phase II',
      'Phase I/II',
      'Phase I',
      'Preclinical',
      'Inactive',
      'Discontinued',
    ],
  })
)({
  ...RespiratoryCandidatesModel,
  name: 'Non-Vaccine Candidates',
  endpoint: 'treatment-candidate',
  renderDetailView: InfluenzaDetailView,
  defaultSortObject: [
    {
      id: 'manualLatestPhase',
      sortOrder: SortOrders.ASC,
    },
    {
      id: 'treatmentTiming',
      sortOrder: SortOrders.DESC,
    },
  ],
})

export const InfluenzaCandidatesCombinationVaccinesModel = compose<
  typeof RespiratoryCandidatesModel
>(
  whiteListColumn([
    'linkInfectiousDisease',
    'candidateName',
    'alternateNames',
    'manualLatestPhase',
    'vaccineGroupTypeSplit',
    'linkCompanies',
    'linkResearchInstitutions',
    'administrationRoute',
  ]),
  updateColumnAttributes('manualLatestPhase', {
    sortOrderObject: [
      'Approved',
      'Phase III',
      'Phase II/III',
      'Phase II',
      'Phase I/II',
      'Phase I',
      'Preclinical',
      'Inactive',
      'Discontinued',
    ],
  })
)({
  ...RespiratoryCandidatesModel,
  name: 'Combination vaccines',
  endpoint: 'vaccine-candidate',
  renderDetailView: InfluenzaDetailView,
  defaultSortOrder: SortOrders.ASC,
  defaultSortKey: 'manualLatestPhase',
})

export const InfluenzaCandidatePapersModel = compose<typeof PresentationsModel>(
  whiteListColumn([
    'title',
    'journalTitle',
    'publicationDate',
    'candidateNameFromVaccinesCandidateList',
    'candidateNameFromTreatmentsCandidateList',
    'articleTypesSpecific',
    'lookupJournalImpactScore',
    'articleLink',
  ]),
  updateColumnAttributes('afTopics', { type: ValueType.SINGLE }),
  updateColumnAttributes('candidateNameFromVaccinesCandidateList', {
    graphType: 'Bar',
  }),
  updateColumnAttributes('candidateNameFromTreatmentsCandidateList', {
    graphType: 'Bar',
  }),
  updateColumnAttributes('afTopics', { graphType: 'Pie' })
)({
  ...PresentationsModel,
  name: 'Publications',
  chartColumnBlackList: [
    'title',
    'journalTitle',
    'articleTypesSpecific',
    'publicationDate',
    'afEfficacySentiment',
    'afTags',
    'concatOrganisations',
    'targetEnrollmentFromClinicalTrials',
    'articleViews',
    'pdfDownloads',
    'lookupJournalImpactScore',
    'tweetMentions',
    'socialScore',
  ],
  endpoint: 'publication',
  detailView: {
    link: 'doiUrl',
    abstract: 'abstract',
  },
  exportDisabled: true,
})

export const InfluenzaConferencePresentionsModel = compose<
  typeof ConferencePresentationsModel
>(
  whiteListColumn([
    'startDate',
    'eventName',
    'sessionType',
    'titleClean',
    'descriptionClean',
    'candidateNameFromLinkVaccineCandidate',
    'candidateNameFromLinkTreatmentCandidate',
    'afSentiment',
    'sessionTypeKey',
  ]),
  renameColumn('startDate', 'Date'),
  updateColumnAttributes('sessionType', { graphType: 'Pie' }),
  updateColumnAttributes('candidateNameFromLinkVaccineCandidate', {
    graphType: 'Bar',
  }),
  updateColumnAttributes('candidateNameFromLinkTreatmentCandidate', {
    graphType: 'Bar',
  }),
  renameColumn('candidateNameFromLinkTreatmentCandidate', 'Non-Vaccines')
)({
  ...ConferencePresentationsModel,
  exportDisabled: true,
  chartColumnBlackList: [
    'startDate',
    'eventName',
    'titleClean',
    'descriptionClean',
    'afSentiment',
    'sessionTypeKey',
  ],
  name: 'Conferences',
  detailView: {
    abstract: 'descriptionClean',
  },
  displayKey: 'titleClean',
  defaultSortObject: [
    { id: 'eventName', sortOrder: SortOrders.DESC },
    { id: 'sessionTypeKey', sortOrder: SortOrders.ASC },
  ],
})

export const InfluenzaTrialsModel = compose<typeof ClinicalTrialsModel>(
  whiteListColumn([
    'clinicalTrialId',
    'title',
    'eidList',
    'acronym',
    'candidateNameFromVaccineCandidateList',
    'candidateNameFromTreatmentCandidateList',
    'sponsorOrgSync',
    'phasesClean',
    'status',
    'startDate',
    'enrolledAt',
    'primaryCompletionDate',
    'completedAt',
    'targetEnrollment',
    'participants',
    'studyTypeClean',
    'ages',
    'primaryOutcomeMeasures',
    'locations',
    'url',
  ]),
  renameColumn('registeredAt', 'Registered'),
  renameColumn('sponsorOrgSync', 'Sponsors'),
  renameColumn('phasesClean', 'Phase'),
  updateColumnAttributes('phasesClean', { graphType: 'Bar' }),
  updateColumnAttributes('candidateNameFromVaccineCandidateList', {
    graphType: 'Bar',
  }),
  updateColumnAttributes('candidateNameFromTreatmentCandidateList', {
    graphType: 'Bar',
  }),
  updateColumnAttributes('locations', { graphType: 'Bar' })
)({
  ...ClinicalTrialsModel,
  chartColumnBlackList: [
    'clinicalTrialId',
    'eidList',
    'title',
    'acronym',
    'status',
    'url',
    'conditions',
    'interventions',
    'targetEnrollment',
    'studyTypeClean',
    'studyDesign',
    'ages',
    'gender',
    'enrolledAt',
    'fStudyCompletion',
    'primaryOutcomeMeasures',
    'sponsor',
    'collaborators',
    'outcomeMeasures',
  ],
  displayKey: 'clinicalTrialId',
  defaultSortOrder: SortOrders.DESC,
  defaultSortKey: 'enrolledAt',
  // excludeGeneratedCharts: true,
})

export const InfluenzaPressReleasesModel = compose<typeof PressReleasesModel>(
  whiteListColumn([
    'title',
    'linkInfectiousDisease',
    'datePublished',
    'afTopic',
    'link',
    'candidateNameFromVaccinesCandidateList',
    'candidateNameFromTreatmentsCandidateList',
    'companies',
    'afTags',
  ]),
  updateColumnAttributes('candidateNameFromVaccinesCandidateList', {
    graphType: 'Bar',
  }),
  updateColumnAttributes('candidateNameFromTreatmentsCandidateList', {
    graphType: 'Bar',
  }),
  renameColumn(
    'candidateNameFromTreatmentsCandidateList',
    'Non-Vaccines Candidate List'
  ),
  updateColumnAttributes('afTopic', { graphType: 'Pie' })
)({
  ...PressReleasesModel,
  chartColumnBlackList: [
    'title',
    'linkInfectiousDisease',
    'datePublished',
    'link',
    'companies',
    'afTags',
  ],
})
export const InfluenzaMediaModel = compose<typeof MediaModel>(
  whiteListColumn([
    'title',
    'linkInfectiousDisease',
    'companies',
    'afTopic',
    'datePublished',
    'link',
    'candidateNameFromVaccinesCandidateList',
    'candidateNameFromTreatmentsCandidateList',
    'company',
    'afTags',
  ]),
  updateColumnAttributes('afTopic', { graphType: 'Pie' }),
  updateColumnAttributes('candidateNameFromVaccinesCandidateList', {
    graphType: 'Bar',
  }),
  updateColumnAttributes('candidateNameFromTreatmentsCandidateList', {
    graphType: 'Bar',
  }),
  renameColumn(
    'candidateNameFromTreatmentsCandidateList',
    'Non-Vaccines Candidate List'
  ),
  renameColumn('company', 'Companies Mentioned'),
  renameColumn('companies', 'Media Provider')
)({
  ...MediaModel,
  chartColumnBlackList: [
    'title',
    'linkInfectiousDisease',
    'companies',
    'datePublished',
    'link',
    'language',
    'author',
    'company',
    'companyResearchInstitution',
    'afTags',
  ],
})

export const NonVaccinesResultsModel = compose<typeof RespiratoryResultsModel>(
  whiteListColumn([
    'candidateNameFromNotes',
    'lookupManualLatestPhase',
    'efficacyEndpoint',
    'otherEfficacyMeasures',
    'url',
    'sourceType',
    'safetyEndpoint',
    'population',
  ])
)(RespiratoryResultsModel)

export const VaccinesResultsModel = compose<typeof RespiratoryResultsModel>(
  whiteListColumn([
    'candidateNameFromNotes',
    'lookupManualLatestPhase',
    'immunogenicityEndpoint',
    'seroconversion',
    'ninetyFiveCiUpper',
    'ninetyFiveCiLower',
    'gmtTitres',
    'url',
    'sourceType',
    'population',
  ])
)(RespiratoryResultsModel)

export const InfluenzaApprovalsModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'luCandidateName',
    'luCompany',
    'targetPopulation',
    'ageIndication',
    'approvalType',
    'linkCountry',
    'regulatoryBody',
    'url',
    'approvalDate',
  ]),
  renameColumn('luCandidateName', 'Candidate')
)({ ...ApprovalsModel })
