import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Grid,
  Heading,
  InputGroup,
  Text,
} from '@chakra-ui/react'
import _ from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'

import { useAppRoute } from 'routes/utils'

import { AuthContext } from 'contexts'

import { InlineLoading, PageHeader } from 'components'

import useReportCategories from 'api/cms/reports/useReportCategories'
import useReports from 'api/cms/reports/useReports'
import { Report, ReportCategory } from 'api/types'

import useTracking from 'tracking/useTracking'

import { apps, appsListUnion } from 'config/apps'

import useIsMobile from 'utils/useIsMobile'
import useThrottle from 'utils/useThrottle'

import { CategoriesDropdown } from './components/CategoriesDropdown'
import MainCard from './components/MainCard'
import MiniCard from './components/MiniCard'
import NothingFound from './components/NothingFound'
import { ReportSearchInput } from './components/ReportSearchInput'
import SlideModal from './components/SlideModal'
import { SortMenu } from './components/SortMenu'
import { groupByMonth } from './utils'

interface ReportPagePropTypes {
  extraComponent?: React.ReactNode
  showCategory?: boolean
  showAppFilter?: boolean
  title?: string
  slugOverwrites?: string[]
  category?: ReportCategory
  researchBrief?: boolean
  EmptyScreen?: React.ComponentType
}

const CheckboxSection = ({
  reportsApps,
  setReportsApps,
}: {
  reportsApps: string[]
  setReportsApps: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  if (!reportsApps) return null
  return (
    <Box w={'100%'} mt='8px' mb='12px'>
      <Text variant='body-bold' mb='4px' w='fit-content'>
        Filters{' '}
      </Text>
      <CheckboxGroup
        defaultValue={reportsApps}
        onChange={(apps: string[]) => setReportsApps(apps)}
      >
        <Grid w={'100%'} gridTemplateColumns='repeat(auto-fit,200px)'>
          {reportsApps.map((appValue) => (
            <Checkbox value={appValue}>
              <Text variant='body2'>{appValue}</Text>
            </Checkbox>
          ))}
        </Grid>
      </CheckboxGroup>
    </Box>
  )
}

const ReportPage = ({
  extraComponent,
  showCategory = true,
  showAppFilter = false,
  title = 'Reports',
  slugOverwrites = ['updates'],
  category,
  EmptyScreen,
}: ReportPagePropTypes) => {
  const [isMobile] = useIsMobile()
  const [tracking] = useTracking()
  const { userInfo } = useContext(AuthContext)
  const selectedApp = useAppRoute()

  const [search, setSearch] = useState('')
  const searchString = useThrottle(search, 200)
  const [selectedReportApps, setReportsApps] = useState([''])
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [selected, setSelected] = useState<Report | null>(null)
  const [selectedCategory, setSelectedCategory] =
    useState<ReportCategory | null>(null)

  const appInfo = apps[selectedApp as appsListUnion]

  const premiumGroups = appInfo.premiumGroup || []
  const premiumAccess = userInfo?.groups.includes(premiumGroups[0])

  const categoriesRes = useReportCategories(
    selectedApp,
    slugOverwrites,
    { enabled: showCategory },
    premiumAccess ? appInfo.premiumSlug : undefined
  )

  const categories = useMemo(
    () =>
      categoriesRes
        ?.map((category) => category?.data)
        .flat()
        .filter((cat) => !!cat),
    [categoriesRes]
  )

  const finalCategory = selectedCategory || category

  const reportsRes = useReports(
    selectedApp,
    slugOverwrites,
    {
      categoryName: finalCategory?.name,
      categoryType: finalCategory?.type,
      searchText: searchString,
    },
    {},
    premiumAccess ? appInfo.premiumSlug : undefined
  )

  const isLoading = reportsRes.some((res) => res.isLoading)

  const rawReports = useMemo(
    () =>
      reportsRes
        ?.map((reports) => reports?.data)
        .flat()
        .filter((report) => !!report),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading]
  )

  const sortedReports = useMemo(
    () =>
      _.orderBy(rawReports, 'date', sortOrder).filter((report) =>
        report?.apps.some((item) => selectedReportApps.includes(item))
      ),
    [rawReports, sortOrder, selectedReportApps]
  )
  const groupedReports = useMemo(
    () => groupByMonth(sortedReports),
    [sortedReports]
  )

  const reportsApps = useMemo(
    () => [...new Set(rawReports?.map((report) => report?.apps || '').flat())],
    [rawReports]
  )

  useEffect(() => {
    setReportsApps(reportsApps)
  }, [reportsApps])

  return (
    <Box overflow='auto'>
      <Box flex={1} pt={3} display='flex' flexDirection='column'>
        <PageHeader title={title} />
        {extraComponent}
        <InputGroup flexDir={isMobile ? 'column' : 'row'}>
          {showCategory && categories && (
            <CategoriesDropdown
              isMobile={isMobile}
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          )}
          <ReportSearchInput
            isMobile={isMobile}
            onBlur={() =>
              searchString &&
              tracking.searchReports({ searchValue: searchString })
            }
            showCategory={showCategory}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputGroup>
        <SlideModal
          isMobile={isMobile}
          selected={selected}
          type='Report'
          setSelected={() => setSelected(null)}
        />
        <Box border='1px solid' borderColor='gray3' borderRadius='8px' my={6}>
          {isLoading ? (
            <Flex alignItems='center' justifyContent='center' height='68px'>
              <InlineLoading />
            </Flex>
          ) : (
            <>
              <Flex
                justifyContent='space-between'
                minHeight='68px'
                px={8}
                alignItems='center'
              >
                {showAppFilter && (
                  <CheckboxSection
                    reportsApps={reportsApps}
                    setReportsApps={setReportsApps}
                  />
                )}
                <Flex
                  alignItems={isMobile ? 'initial' : 'center'}
                  flexDir={isMobile ? 'column' : 'row'}
                  my={isMobile ? 4 : 0}
                  minH={isMobile ? 'max-content' : 'initial'}
                >
                  {(selectedCategory || searchString) && (
                    <Heading variant='h3' whiteSpace={'nowrap'} mr='1rem'>
                      {sortedReports.length} Result
                      {sortedReports.length > 1 && 's'}
                    </Heading>
                  )}
                  <SortMenu sortOrder={sortOrder} setSortOrder={setSortOrder} />
                </Flex>
              </Flex>
              {Object.keys(groupedReports).length > 0 ? (
                Object.keys(groupedReports).map((key, index) => {
                  return (
                    <MainCard key={index} keyStr={key}>
                      {groupedReports[key].map((report, i) => {
                        if (!report) return null
                        const { title } = report
                        return (
                          <MiniCard
                            isMobile={isMobile}
                            key={i}
                            onClick={() => {
                              tracking.openReport({
                                report: title,
                                type: 'summary',
                              })
                              setSelected(report)
                            }}
                            report={report}
                          />
                        )
                      })}
                    </MainCard>
                  )
                })
              ) : EmptyScreen ? (
                <EmptyScreen />
              ) : (
                <NothingFound searchString={searchString} />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ReportPage
