import { Box } from '@chakra-ui/react'

import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'

import { Panel, PanelBody, RShinyEmbed, TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { Covid19CommercialVaccinesSlugs } from './Covid19CommercialVaccinesMenu'
import { DealsPricePages } from './Covid19CommercialVaccinesPages'

const DashboardPage: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: '',
  component: () => <RShinyEmbed path='vaccine_stock_market' height='80vh' />,
}

const ProductionByVaccine: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['deliveries', 'by-vaccine'],
  component: () => (
    <TableauEmbed path='/production_dashboards/Vaccineproduction' />
  ),
}

const VaccineDonations: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['donations', 'vaccine-donations'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Donations by Vaccine',
          body: (
            <TableauEmbed path='/COVID-19VaccineDonations-WIP/ShareofdonatedVaccines' />
          ),
        },
        {
          heading: 'Donations by Country',
          body: (
            <TableauEmbed path='/COVID-19VaccineDonations-WIP/DonorsRecipients' />
          ),
        },
        {
          heading: 'Total Donations',
          body: (
            <TableauEmbed path='/COVID-19VaccineDonations-WIP/VaccineDonations' />
          ),
        },
        {
          heading: 'Donations Map',
          body: (
            <TableauEmbed path='/COVID-19VaccineDonations-WIP/VaccineRecipientsandDonorsMap' />
          ),
        },
      ]}
    />
  ),
}

const Covax: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['donations', 'covax'],
  component: () => (
    <TableauEmbed path='/COVAXcoverage/CovaxCoverage' height={'150vh'} />
  ),
}

const DealsPrice: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['deals', 'price'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Overview',
          body: (
            <TableauEmbed
              path='/VaccinePricing_16602264533180/Vxpricedashboard'
              height={'calc(100vh - 150px)'}
            />
          ),
        },
        ...Object.values(DealsPricePages),
      ]}
    />
  ),
}

const ReportedRevenuesDashboard: ISingleCustomPage<Covid19CommercialVaccinesSlugs> =
  {
    path: ['revenue', 'reported-revenues'],
    component: () => (
      <Box mt='1rem'>
        <TableauEmbed path='/Vx_Rev_1_1/Dashboard1' />
      </Box>
    ),
  }

const DealsSupplyAgreements: ISingleCustomPage<Covid19CommercialVaccinesSlugs> =
  {
    path: ['deals', 'supply-agreements'],
    component: () => (
      <TabbedDisplay
        tabs={[
          {
            heading: 'By Destination and Vaccine',
            body: (
              <TableauEmbed path='/supplyagreementsandapprovals/Dashboard1' />
            ),
          },
          {
            heading: 'Over Time',
            body: (
              <TableauEmbed path='/supplyagreementsandapprovals/Dashboard2' />
            ),
          },
        ]}
      />
    ),
  }

const VaccinationsByManufacturerPage: ISingleCustomPage<Covid19CommercialVaccinesSlugs> =
  {
    path: ['demand', 'vaccinations-manufacturer'],
    component: () => (
      <Box p={3} overflow='auto'>
        <Box display='flex' pos='relative'>
          <Panel overflow='hidden' zIndex='1'>
            <PanelBody p={0}>
              <RShinyEmbed path='vaccinations_by_manufacturer' height='120vh' />
            </PanelBody>
          </Panel>
        </Box>
      </Box>
    ),
  }

const Reports: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['reports', 'reports'],
  component: () => (
    <FullReportPage
      showCategory={false}
      showAppFilter
      slugOverwrites={['updates', 'full-report']}
      researchBrief
    />
  ),
}

const ResearchBriefs: ISingleCustomPage<Covid19CommercialVaccinesSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const RevenueForecastReports: ISingleCustomPage<Covid19CommercialVaccinesSlugs> =
  {
    path: ['revenue', 'forecast-report'],
    component: () => (
      <FullReportPage showCategory={false} slugOverwrites={['deep-dive']} />
    ),
  }
const VaccinationCampaigns: ISingleCustomPage<Covid19CommercialVaccinesSlugs> =
  {
    path: ['demand', 'vaccination-campaigns'],
    component: () => (
      <TableauEmbed path='/AutumnWinterPolicy/Sheet2' height={'130vh'} />
    ),
  }

export const Covid19CommercialVaccinesCustomPages: Record<
  string,
  ISingleCustomPage<Covid19CommercialVaccinesSlugs>
> = {
  DashboardPage,
  DealsPrice,
  DealsSupplyAgreements,
  ProductionByVaccine,
  VaccineDonations,
  Covax,
  VaccinationsByManufacturerPage,
  Reports,
  RevenueForecastReports,
  ReportedRevenuesDashboard,
  VaccinationCampaigns,
  ResearchBriefs,
}
