import TabbedDisplay from 'modules/TabbedDisplay'

import FullReportPage from 'routes/common/FullReportPage'
import ResearchBriefsPage from 'routes/common/FullReportPage/components/ResearchBriefs'

import { TableauEmbed } from 'components'

import { ISingleCustomPage } from 'interfaces/navigationPage.interface'

import { Covid19CommercialTherapeuticsSlugs } from './Covid19CommercialTherapeuticsMenu'
import {
  DealsManufacturingAndProductionDealsPages,
  DealsPricePages,
  DealsSupplyPages,
  DevelopersPages,
  ManufacturersPages,
} from './Covid19CommercialTherapeuticsPages'

const Dashboard: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: '',
  component: () => (
    <TableauEmbed
      path='/CandidateOverviewbyCountry/CandidateOverviewbyCountry'
      height='120vh'
    />
  ),
}

const Developers: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['organisations', 'developers'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Developers by Country',
          body: (
            <TableauEmbed
              path='/TxOrganisations/DevelopersDashboard'
              height='120vh'
            />
          ),
        },
        ...Object.values(DevelopersPages),
      ]}
    />
  ),
}

const Manufacturers: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['organisations', 'manufacturers'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Manufacturers by Country',
          body: (
            <TableauEmbed
              path='/TxOrganisations/ManufacturersDashboard'
              height='120vh'
            />
          ),
        },
        ...Object.values(ManufacturersPages),
      ]}
    />
  ),
}

const SupplyOverview: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['supply', 'overview'],
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Supply Deals by Country',
          body: (
            <TableauEmbed path='/CandidateOverviewbyCountry/SupplyDashboard' />
          ),
        },
        ...Object.values(DealsSupplyPages),
      ]}
    />
  ),
}

const SupplyTimeseries: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> =
  {
    path: ['supply', 'timeseries'],
    component: () => (
      <TabbedDisplay
        tabs={[
          {
            heading: 'Deals',
            body: (
              <TableauEmbed
                path='/TxSupplyDealsTimeseries/SupplyAirtable'
                height='120vh'
              />
            ),
          },
          {
            heading: 'Contracts',
            body: (
              <TableauEmbed
                path='/TxSupplyDealsTimeseries/TimeseriesSupplyContracts'
                height='130vh'
              />
            ),
          },
        ]}
      />
    ),
  }

const CountryProfileUptake: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> =
  {
    path: ['uptake', 'country-profile'],
    component: () => (
      <TableauEmbed path='/COVID-19TxUptakev2/UptakedashboardV3' />
    ),
  }

const Price: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: 'price',
  component: () => (
    <TabbedDisplay
      tabs={[
        {
          heading: 'Dashboard',
          body: (
            <TableauEmbed
              path='/CandidateOverviewbyCountry/PricingDashboard'
              height={'calc(100vh - 150px)'}
            />
          ),
        },
        ...Object.values(DealsPricePages),
      ]}
    />
  ),
}

const ProductionAgreements: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> =
  {
    path: ['production', 'production-agreements'],
    component: () => (
      <TabbedDisplay
        tabs={[
          {
            heading: 'By Production Type',
            body: (
              <TableauEmbed path='/CandidateOverviewbyCountry/ProductionDashboard' />
            ),
          },
          {
            heading: 'By Licensing Agreement',
            body: (
              <TableauEmbed path='/CandidateOverviewbyCountry/LicensingDashboard' />
            ),
          },
          {
            heading: 'All Agreements',
            body: Object.values(DealsManufacturingAndProductionDealsPages)[0],
          },
        ]}
      />
    ),
  }

const RevenueToDate: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['revenue', 'to-date'],
  component: () => (
    <TableauEmbed path='/TxRevenues/Dashboard3' height='120vh' />
  ),
}

const Reports: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['reports', 'reports'],
  component: () => (
    <FullReportPage
      showCategory={false}
      showAppFilter
      slugOverwrites={['updates', 'full-report']}
    />
  ),
}

const ResearchBriefs: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['reports', 'research-briefs'],
  component: () => <ResearchBriefsPage />,
}

const ForecastReport: ISingleCustomPage<Covid19CommercialTherapeuticsSlugs> = {
  path: ['revenue', 'forecast-report'],
  component: () => (
    <FullReportPage
      showCategory={false}
      title='Forecast Report'
      slugOverwrites={['deep-dive']}
    />
  ),
}

export const Covid19CommercialTherapeuticsCustomPages: Record<
  string,
  ISingleCustomPage<Covid19CommercialTherapeuticsSlugs>
> = {
  Dashboard,
  Developers,
  Manufacturers,
  SupplyOverview,
  SupplyTimeseries,
  Price,
  RevenueToDate,
  Reports,
  ProductionAgreements,
  ForecastReport,
  CountryProfileUptake,
  ResearchBriefs,
}
