import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

export type RevenueData =
  | 'organisations'
  | 'candidates'
  | 'areas'
  | 'dateStart'
  | 'dateEnd'
  | 'value'
  | 'originalCurrency'
  | 'valueUsd'
  | 'dosesSold'
  | 'rssArticles'
  | 'financialQuarter'
  | 'financialYear'
  | 'quarter'
  | 'designationA'
  | 'designationB'
  | 'sources'
  | 'seasonalOrPandemic'

const allowedAirtables = [
  'flu_commercial_revenue',
  'flu_commercial_revenue_vaccines',
  'flu_commercial_revenue_treatments',
  'flu_commercial_revenue_vaccines_seasonal',
  'flu_commercial_revenue_vaccines_pandemic',
] as const

export const RevenueModel: IModel<
  Record<RevenueData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'organisations',
  name: 'Revenue',
  entityName: 'Revenue',
  searchField: 'organisations',
  endpoint: 'revenue',
  detailViewType: 'Generic',
  schema: {
    columns: [
      {
        key: 'organisations',
        label: 'Companies',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'candidates',
        label: 'Candidates',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'areas',
        label: 'Location',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'dateStart',
        label: 'Date Start',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'dateEnd',
        label: 'Date End',
        type: ValueType.DATE,
        width: 150,
      },
      {
        key: 'value',
        label: 'Value (raw)',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'originalCurrency',
        label: 'Original Currency',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'valueUsd',
        label: 'Value (USD)',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'dosesSold',
        label: 'Doses Sold',
        type: ValueType.NUMBER,
        width: 150,
      },
      {
        key: 'rssArticles',
        label: 'Source',
        type: ValueType.URL,
        width: 300,
      },
      {
        key: 'financialYear',
        label: 'Financial Year',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'financialQuarter',
        label: 'Quarter',
        type: ValueType.TEXT,
        width: 250,
      },
      {
        key: 'quarter',
        label: 'Quarter',
        type: ValueType.TEXT,
        width: 250,
      },
      {
        key: 'designationA',
        label: 'Vaccine or Treatment',
        type: ValueType.SINGLE,
        width: 250,
      },
      {
        key: 'designationB',
        label: 'Category',
        type: ValueType.MULTI,
        width: 250,
      },
      {
        key: 'sources',
        label: 'Sources',
        type: ValueType.URL,
        width: 250,
      },
      {
        key: 'seasonalOrPandemic',
        label: 'Seasonal or Pandemic',
        type: ValueType.TEXT,
        width: 180,
      },
    ],
  },
}
