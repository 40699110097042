import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosInstance } from 'axios'
import { useContext } from 'react'

import { AuthContext } from 'contexts'

import { IBaseView } from 'interfaces/navigationPage.interface'

import { apps, appsListUnion } from 'config/apps'

import { WebMeta } from './types'
import useAxios from './useAxios'

export type FetchCollectionMetaQueries = Partial<{
  airtableBase: IBaseView<any>['airtableBase']
  view: string | null
}>

export default function useCollectionMeta(
  app: appsListUnion,
  collection: string,
  queries: FetchCollectionMetaQueries,
  ids: (string | number)[],
  extraProps?: UseQueryOptions<any, any>
) {
  const axios = useAxios()

  const { userInfo } = useContext(AuthContext)
  const selectedApp = apps[app as appsListUnion]
  const premiumGroup = selectedApp.premiumGroup || []
  const premiumAccess = userInfo?.groups.includes(premiumGroup[0])
  const endpoint = premiumAccess
    ? apps[app].premiumSlug || apps[app].endpoint
    : apps[app].endpoint

  return useQuery<WebMeta[], AxiosError>(
    ['collectionMeta', app, collection, ids],
    () =>
      Promise.all(
        ids.map((id) =>
          fetchCollectionMeta(axios, app, collection, id, queries, endpoint)
        )
      ),
    { enabled: !!ids, ...extraProps }
  )
}

export const fetchCollectionMeta = async (
  axios: AxiosInstance,
  app: appsListUnion,
  collection: string,
  id: string | number,
  queries: FetchCollectionMetaQueries,
  endpoint?: string
) => {
  return axios
    .get(
      `${queries.airtableBase ?? 'covid'}/${
        endpoint || apps[app].endpoint
      }/${collection}/${id}/microlink/`
    )
    .then((res) => res.data as WebMeta)
}
