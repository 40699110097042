import { Flex } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'

import { INavigationMenu } from 'interfaces/navigationMenu.interface'

import { isNew } from 'utils/isNew'

import PrimaryLink from '../PrimaryLink'
import NewTag from './NewTag'
import NoAccessNavigationEntry from './NoAccessNavigationEntry'

type SimpleNavigationEntryPropType = {
  menu: INavigationMenu<any>
  onSelect: () => void
  isActive: boolean
  isFirst?: boolean
  isLast?: boolean
  link: string
  isDropdownChild?: boolean
  hasAccess?: boolean
  newUntil?: Date
}

export default function SimpleNavigationEntry({
  menu,
  onSelect,
  isActive,
  isFirst,
  isLast,
  link,
  isDropdownChild,
  hasAccess,
}: SimpleNavigationEntryPropType) {
  const isNewBool = isNew(menu.createdAt, menu.newUntil)
  const isUpdatedBool = menu.updatedAt ? isNew(menu.updatedAt) : undefined
  if (!hasAccess) {
    return (
      <NoAccessNavigationEntry
        isFirst={isFirst}
        isLast={isLast}
        isDropdownChild={isDropdownChild}
        isActive={isActive}
        title={menu.title}
      />
    )
  }
  return (
    <NavLink
      key={menu.slug}
      to={link}
      style={{
        display: 'block',
      }}
      onClick={onSelect}
      data-cy='sidebar-navigation-item'
    >
      <Flex alignItems={'center'}>
        <PrimaryLink
          isFirst={isFirst}
          isLast={isLast}
          isDropdownChild={isDropdownChild}
          isActive={isActive}
          isNew={isNewBool}
        >
          {menu.title}
        </PrimaryLink>
        {isNewBool ? (
          <NewTag isDropdownChild={isDropdownChild} isSelected={isActive} />
        ) : null}
        {isUpdatedBool ? (
          <NewTag
            isSelected={isActive}
            isDropdownChild={isDropdownChild}
            customText='New Data'
          />
        ) : null}
      </Flex>
    </NavLink>
  )
}
