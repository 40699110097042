import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import Phase from '../tagColours/Phase'
import StudyDesignClean from '../tagColours/StudyDesignClean'
import StudyType from '../tagColours/StudyType'
import TreatmentType from '../tagColours/TreatmentType'

export type ClinicalTrialsData =
  | 'vaccine'
  | 'company'
  | 'phasesClean'
  | 'afDateAdded'
  | 'afStartDate'
  | 'completionDate'
  | 'completedAt'
  | 'enrolledAt'
  | 'indication'
  | 'primaryOutcome'
  | 'secondaryOutcome'
  | 'participants'
  | 'locations'
  | 'lookupInvestigatorNamesFromInvestigators'
  | 'url'
  | 'name'
  | 'title'
  | 'studyTypeClean'
  | 'afUniqueTreatmentName'
  | 'acronym'
  | 'ages'
  | 'gender'
  | 'conditions'
  | 'sponsors'
  | 'collaborators'
  | 'stageOfIntervention'
  | 'outcomeMeasures'
  | 'isRetrospective'
  | 'primaryCompletionDate'
  | 'fStudyCompletion'
  | 'status'
  | 'afIndication'
  | 'interventions'
  | 'candidateList'
  | 'luCandidateName'
  | 'luDrugClass'
  | 'targetEnrollment'
  | 'sponsorOrgSync'
  | 'registeredAt'
  | 'updatedAt'
  | 'investigators'
  | 'locationsSync'
  | 'afProject'
  | 'afRelevant'
  | 'published'
  | 'nctId'
  | 'lookupCandidateName'
  | 'treatmentTypeLookup'
  | 'studyDesignClean'
  | 'govId'
  | 'patientSetting'
  | 'afTrType'
  | 'routesOfAdministrationClinicalTrial'
  | 'primaryOutcomeMeasures'
  | 'primaryOutcomeMeasuresClean'
  | 'secondaryOutcomeMeasuresClean'
  | 'secondaryOutcomeMeasures'
  | 'priorityLookup'
  | 'inclusionCriteria'
  | 'exclusionCriteria'
  | 'allocation'
  | 'interventionModel'
  | 'masking'
  | 'primaryPurpose'
  | 'observational'
  | 'timePerspectiveObservational'
  | 'conditions'
  | 'completedAt'
  | 'sponsor'
  | 'arms'
  | 'trialDurationMonths'
  | 'luInvestigatorOrCommitteeMemberOfClinicalTrialFromPersons'
  | 'collaborator'
  | 'clinicalTrialId'
  | 'trialId'
  | 'eidList'
  | 'linkVaccineCandidateList'
  | 'linkTreatmentCandidateList'
  | 'studyDesign'
  | 'candidateNameFromVaccineCandidateList'
  | 'candidateNameFromTreatmentCandidateList'
  | 'patientConditions'
  | 'sourceRegister'
  | 'targetEnrollmentText'
  | 'lookupSponsorNameFromSponsors'
  | 'likelyDominantVariant'
  | 'luLikelyDominantVariantAtThisPointInTime'
  | 'conditionsIndicationAutoIgm'
  | 'conditionsIndicationAutoMm360'
  | 'analysisPrediction'
  | 'fundingBodyOrOrg'
  | 'researchInstitute'
  | 'sponsorPersonSync'
  | 'linkFundingBodyOrg'
  | 'linkResearchInstitute'
  | 'ageBracket'
  | 'studyType'
  | 'linkLocations'
  | 'priority'
  | 'candidateClass'
  | 'detailedDeliverySystem'
  | 'routeOfAdministration'
  | 'id'
  | 'latestPhaseFinal'
  | 'startDate'
  | 'numberOfParticipants'
  | 'population'
  | 'patientComorbidities'
  | 'candidates'
  | 'areas'
  | 'diseaseName'
  | 'rawPrimaryOutcomeMeasures'
  | 'rawSecondaryOutcomeMeasures'
  | 'candidateType'
  | 'candidateSubType'
  | 'designationA'
  | 'designationB'
  | 'comparatorVaccine'
  | 'pathogenTypes'
  | 'linkDisease'
  | 'developers'
  | 'linkInfectiousDisease'
  | 'linkCandidateIds'

const allowedAirtables = [
  'api_app_treatments',
  'api_igm',
  'api_igm_3_month',
  'api_multiple_myeloma',
  'api_multiple_myeloma_3_month',
  'api_multiple_myeloma_360',
  'api_multiple_myeloma_360_3_month',
  'api_lipid_360',
  'front_end_export_influenza_default_view',
  'front_end_export_influenza_vaccines_view',
  'front_end_export_influenza_treatments_view',
  'front_end_export_rsv_default_view',
  'front_end_export_rsv_vaccines_view',
  'front_end_export_rsv_treatments_view',
  'api_anti-thrombotics_clinical_trials',
  'RNA_API_ClinicalTrials',
  'hpv',
  'id_vaccines',
  'id_all',
  'id_treatments',
  'api_phase_all',
  'FE Long COVID Trials table',
  'Obesity trials - Platform View',
  'Obesity trials - Platform View - Industry Sponsored',
  'Obesity trials - Platform View - Non-Industry Sponsored',
] as const

export const ClinicalTrialsModel: IModel<
  Record<ClinicalTrialsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  name: 'Clinical Trials',
  entityName: 'Clinical Trials',
  displayKey: 'nctId',
  detailViewType: 'Generic',
  defaultSortKey: 'phasesClean',
  defaultSortOrder: SortOrders.ASC,
  endpoint: 'trial',
  schema: {
    columns: [
      {
        key: 'pathogenTypes',
        label: 'Pathogen Types',
        width: 180,
        type: ValueType.MULTI,
      },
      { label: 'Vaccine', key: 'vaccine', width: 200 },
      {
        label: 'Company',
        key: 'company',
        type: ValueType.SINGLE,
        width: 300,
      },
      {
        label: 'Phase',
        key: 'phasesClean',
        width: 140,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: Phase,
        },
        sortOrderObject: [
          'III',
          'II/III',
          'I/II',
          'II',
          'I',
          'IV',
          'Not Applicable',
        ],
      },
      {
        key: 'comparatorVaccine',
        label: 'Comparator Candidate',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'afDateAdded',
        type: ValueType.DATE,
        label: 'Date added',
        width: 200,
      },
      {
        key: 'afStartDate',
        label: 'Start Date',
        width: 200,
        type: ValueType.DATE,
      },
      {
        key: 'completionDate',
        label: 'Completion Date',
        width: 120,
        type: ValueType.DATE,
      },
      {
        key: 'completedAt',
        label: 'Completion date',
        width: 150,
        type: ValueType.DATE,
      },
      {
        label: 'Indication',
        key: 'indication',
        width: 400,
        type: ValueType.TEXT,
      },
      {
        key: 'enrolledAt',
        label: 'Enrolled Date',
        width: 120,
        type: ValueType.DATE,
      },
      {
        key: 'primaryOutcome',
        label: 'Primary outcome',
        type: ValueType.LONG,
        width: 300,
      },
      {
        key: 'secondaryOutcome',
        label: 'Secondary outcome',
        type: ValueType.LONG,
        width: 300,
      },
      {
        key: 'primaryOutcomeMeasures',
        label: 'Primary outcome',
        width: 300,
        type: ValueType.LONG,
        cellFormat: {
          formatForOncology: true,
        },
      },
      {
        key: 'primaryOutcomeMeasuresClean',
        label: 'Primary Outcome/s',
        width: 300,
        type: ValueType.LONG,
        cellFormat: {
          formatForOncology: true,
        },
      },
      {
        key: 'secondaryOutcomeMeasuresClean',
        label: 'Secondary Outcome/s ',
        width: 300,
        type: ValueType.LONG,
        cellFormat: {
          formatForOncology: true,
        },
      },
      {
        key: 'secondaryOutcomeMeasures',
        label: 'Secondary outcome',
        width: 300,
        type: ValueType.LONG,
        cellFormat: {
          formatForOncology: true,
        },
      },
      {
        label: 'Participants',
        key: 'participants',
        headerStyle: {
          textAlign: 'right',
        },
        cellFormat: {
          format: 'NUMBER',
        },
        type: ValueType.TEXT,
      },
      {
        key: 'locations',
        label: 'Locations',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'lookupInvestigatorNamesFromInvestigators',
        label: 'Investigator names',
        type: ValueType.MULTI,
      },
      {
        key: 'title',
        label: 'Title',
        width: 500,
      },
      {
        key: 'studyTypeClean',
        label: 'Study Type',
        width: 130,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: StudyType,
        },
      },
      {
        key: 'afUniqueTreatmentName',
        label: 'Treatments',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'linkDisease',
        label: 'Diseases',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'linkInfectiousDisease',
        label: 'Disease',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'acronym',
        type: ValueType.TEXT,
        width: 120,
        label: 'Acronym',
      },
      {
        key: 'url',
        label: 'URL',
        type: ValueType.URL,
        width: 120,
      },
      {
        key: 'ages',
        label: 'Ages',
        width: 300,
        type: ValueType.SINGLE,
      },
      {
        key: 'gender',
        label: 'Gender',
        width: 85,
        type: ValueType.SINGLE,
      },
      {
        key: 'conditions',
        label: 'Conditions',
        width: 260,
      },
      {
        key: 'sponsors',
        label: 'Sponsors',
        width: 260,
        type: ValueType.MULTI,
      },
      {
        key: 'collaborators',
        label: 'Collaborators',
        width: 200,
      },
      {
        key: 'stageOfIntervention',
        label: 'Stage of intervention',
        type: ValueType.MULTI,
        width: 160,
      },
      {
        key: 'outcomeMeasures',
        label: 'Outcome measures',
        width: 260,
      },
      {
        key: 'isRetrospective',
        label: 'Is retrospective?',
        width: 140,
        type: ValueType.SINGLE,
      },
      {
        key: 'primaryCompletionDate',
        label: 'Primary Completion Date',
        width: 200,
        type: ValueType.DATE,
      },
      {
        key: 'fStudyCompletion',
        label: 'Study Completion',
        width: 140,
        type: ValueType.DATE,
      },

      { key: 'status', label: 'Status', width: 150, type: ValueType.SINGLE },
      {
        key: 'afIndication',
        label: 'Indication',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'rawPrimaryOutcomeMeasures',
        label: 'Primary Outcome',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'rawSecondaryOutcomeMeasures',
        label: 'Secondary Outcome',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'conditionsIndicationAutoIgm',
        label: 'Indication',
        width: 200,
        type: ValueType.LONG,
      },
      {
        key: 'conditionsIndicationAutoMm360',
        label: 'Indication',
        width: 200,
        type: ValueType.LONG,
      },
      {
        key: 'interventions',
        label: 'Interventions',
        width: 250,
        type: ValueType.TEXT,
      },
      {
        key: 'candidateList',
        label: 'Candidate List',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateType',
        label: 'Candidate Type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'candidateSubType',
        label: 'Candidate Sub-type',
        type: ValueType.MULTI,
        width: 180,
      },
      {
        key: 'luCandidateName',
        label: 'Candidate Name',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'luDrugClass',
        label: 'Drug Class',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'targetEnrollment',
        label: 'Target Enrollment',
        width: 150,
        type: ValueType.NUMBER,
      },
      {
        key: 'sponsorOrgSync',
        label: 'Sponsors',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'registeredAt',
        label: 'Registered At',
        width: 130,
        type: ValueType.DATE,
      },
      {
        key: 'updatedAt',
        label: 'Updated At',
        width: 150,
        type: ValueType.DATE,
      },
      {
        key: 'investigators',
        label: 'Investigators',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'locationsSync',
        label: 'Locations Sync',
        width: 230,
        type: ValueType.MULTI,
      },
      {
        key: 'afProject',
        label: 'Project',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'afRelevant',
        label: 'Relevant',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'published',
        label: 'Published',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'nctId',
        label: 'ID',
        width: 140,
        type: ValueType.TEXT,
      },
      {
        key: 'lookupCandidateName',
        label: 'Candidate Name',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'trialId',
        label: 'Trial ID',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'treatmentTypeLookup',
        label: 'Type',
        width: 230,
        type: ValueType.MULTI,
        cellFormat: {
          colours: TreatmentType,
        },
      },
      {
        key: 'studyDesignClean',
        label: 'Study Design',
        width: 280,
        type: ValueType.MULTI,
        cellFormat: { colours: StudyDesignClean },
      },
      {
        key: 'govId',
        label: 'Trial ID',
        width: 160,
        type: ValueType.MULTI,
      },
      {
        key: 'patientSetting',
        label: 'Patient Setting',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'afTrType',
        label: 'Type',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'routesOfAdministrationClinicalTrial',
        label: 'Routes of Administration',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'priorityLookup',
        label: 'Priority',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'inclusionCriteria',
        label: 'Inclusion Criteria',
        width: 400,
        type: ValueType.LONG,
        cellFormat: {
          formatForOncology: true,
        },
      },
      {
        key: 'exclusionCriteria',
        label: 'Exclusion Criteria',
        width: 400,
        type: ValueType.LONG,
        cellFormat: {
          formatForOncology: true,
        },
      },
      {
        key: 'allocation',
        label: 'Allocation',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'interventionModel',
        label: 'Intervention Model',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'masking',
        label: 'Masking',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'primaryPurpose',
        label: 'Primary Purpose',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'observational',
        label: 'Observational',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'timePerspectiveObservational',
        label: 'Time Perspective (Observational)',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'conditions',
        label: 'Conditions',
        width: 250,
        type: ValueType.TEXT,
      },
      { key: 'sponsor', label: 'Sponsor', width: 200, type: ValueType.TEXT },
      { key: 'arms', label: 'Arms', width: 300, type: ValueType.TEXT },
      {
        key: 'trialDurationMonths',
        label: 'Trial Duration (months)',
        width: 100,
        type: ValueType.NUMBER,
      },
      {
        key: 'luInvestigatorOrCommitteeMemberOfClinicalTrialFromPersons',
        label: 'Investigator Or Committee Member Of Clinical Trial',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'collaborator',
        label: 'Collaborator',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'clinicalTrialId',
        label: 'Trial ID',
        width: 150,
        type: ValueType.TEXT,
      },
      { key: 'eidList', label: 'Diseases', width: 120, type: ValueType.MULTI },
      {
        key: 'linkVaccineCandidateList',
        label: 'Vaccine Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'linkTreatmentCandidateList',
        label: 'Treatment Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'studyDesign',
        label: 'Study Design',
        width: 300,
        type: ValueType.TEXT,
      },
      {
        key: 'candidateNameFromVaccineCandidateList',
        label: 'Vaccines Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateNameFromTreatmentCandidateList',
        label: 'Treatments Candidate List',
        width: 240,
        type: ValueType.MULTI,
      },
      {
        key: 'patientConditions',
        label: 'Patient Conditions',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'sourceRegister',
        label: 'Source Register',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'targetEnrollmentText',
        label: 'Target Enrollment',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'lookupSponsorNameFromSponsors',
        label: 'Sponsors',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'likelyDominantVariant',
        label: 'Likely Dominant Variant',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'luLikelyDominantVariantAtThisPointInTime',
        label: 'Likely Dominant Variant At This Point In Time',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'analysisPrediction',
        label: 'Forecasted Readout Date',
        width: 200,
        type: ValueType.DATE,
      },
      {
        key: 'fundingBodyOrOrg',
        label: 'Funding Body/Organisation',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'researchInstitute',
        label: 'Research Institute',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'sponsorPersonSync',
        label: 'Sponsors',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'linkFundingBodyOrg',
        label: 'Funding Body/Organisation',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkResearchInstitute',
        label: 'Research Institute',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'ageBracket',
        label: 'Age Group',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'studyType',
        label: 'Study Type',
        width: 150,
        type: ValueType.TEXT,
      },
      {
        key: 'linkLocations',
        label: 'Trial Locations',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'priority',
        label: 'priority',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidateClass',
        label: 'Candidate Class',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'detailedDeliverySystem',
        label: 'Delivery System',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'routeOfAdministration',
        label: 'Route of Administration',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'id',
        label: 'Trial ID',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'latestPhaseFinal',
        label: 'Phase',
        width: 180,
        type: ValueType.MULTI,
        cellFormat: {
          colours: Phase,
        },
      },
      {
        key: 'startDate',
        label: 'Start Date',
        width: 180,
        type: ValueType.DATE,
      },
      {
        key: 'numberOfParticipants',
        label: 'Participants',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'population',
        label: 'Population',
        width: 180,
        type: ValueType.NUMBER,
      },
      {
        key: 'patientComorbidities',
        label: 'Co-morbidities',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'candidates',
        label: 'Candidate',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'areas',
        label: 'Locations',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'diseaseName',
        label: 'Diseases',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'designationA',
        label: 'Vaccine or Treatment',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'designationB',
        label: 'Candidate Category',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'developers',
        label: 'Developers',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'linkCandidateIds',
        label: 'Linked Candidate IDs',
        width: 180,
        type: ValueType.MULTI,
      },
    ],
  },
}
