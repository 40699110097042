import { SortOrders } from 'enums/SortOrders'

import { RevenueModel } from 'config/common/baseModel/Revenue'
import { TendersModel } from 'config/common/baseModel/Tenders'
import { IDTreatmentPremiumDealsModel } from 'config/common/baseModel/ida360/IDPremiumDeals'
import { RespiratoryDealsModel } from 'config/common/baseModel/respiratory360/RespiratoryDeals'
import { RespiratoryImmunisationScheduleModel } from 'config/common/baseModel/respiratory360/RespiratoryImmunisationSchedule'

import {
  compose,
  renameColumn,
  updateColumnAttributes,
  whiteListColumn,
} from 'utils/overrideModel'

export const InfluenzaImmunisationScheduleModel = compose<
  typeof RespiratoryImmunisationScheduleModel
>(
  whiteListColumn([
    'linkCountry',
    'influenzaSeason',
    'immunisationScheduleExpanded',
    'eligibleAgeGroups',
    'eligibleGroupsOther',
    'newlyEligible',
    'percentageOfPopulationEligibleForVaccination',
    'percentageIncreaseInEligiblePopulationFrom2019To20',
  ])
)(RespiratoryImmunisationScheduleModel)

export const InfluenzaDealsPriceModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'recipients',
    'suppliers',
    'candidates',
    'market',
    'pricePerUnitUsd',
    'yearDelivered',
    'sources',
  ]),
  renameColumn('recipients', 'Countries'),
  renameColumn('suppliers', 'Company'),
  renameColumn('pricePerUnitUsd', 'Price Per Vaccine (in USD)'),
  renameColumn('yearDelivered', 'Year'),
  renameColumn('candidates', 'Vaccine Name')
)(IDTreatmentPremiumDealsModel)

export const InfluenzaDealsProductionModel = compose<
  typeof RespiratoryDealsModel
>(
  whiteListColumn([
    'candidateNameFromLinkVaccinesCandidateList',
    'candidateNameFromLinkTreatmentsCandidateList',
    'afDealType',
    'linkOrganisations',
    'countryProduction',
    'afExpectedProduction',
    'linkFromPressReleases',
    'linkFromMediaReleases',
    'afProductionDealType',
    'dateDealMade',
    'afSupplyProductionNotes',
  ])
)(RespiratoryDealsModel)

export const InfluenzaDealsSupplyModel = compose<
  typeof IDTreatmentPremiumDealsModel
>(
  whiteListColumn([
    'recipients',
    'suppliers',
    'candidates',
    'categoryC',
    'market',
    'quantity',
    'status',
    'targetViralStrain',
    'dateStart',
    'yearDelivered',
    'dateEnd',
    'quantityMethodologyType',
    'quantityMethodologyDescription',
    'sources',
  ]),
  renameColumn('recipients', 'Countries Supplied'),
  renameColumn('suppliers', 'Vaccine Producer'),
  renameColumn('candidates', 'Vaccine Name'),
  renameColumn('quantity', 'Doses Supplied'),
  renameColumn('dateStart', 'Date Initiated'),
  renameColumn('dateEnd', 'Concluding Date'),
  renameColumn('sources', 'Source Name'),
  renameColumn('status', 'Seasonal or Pandemic'),
  updateColumnAttributes('dateStart', {
    width: 150,
  }),
  updateColumnAttributes('dateEnd', {
    width: 150,
  }),
  updateColumnAttributes('quantity', {
    width: 150,
  })
)(IDTreatmentPremiumDealsModel)

export const InfluenzaTendersModel = compose<typeof TendersModel>(
  whiteListColumn([
    'tenderNoticeId',
    'title',
    'description',
    'postingDate',
    'deadline',
    'buyer',
    'buyerAddress',
    'areaName',
    'quantity',
    'price',
    'cost',
    'currency',
    'email',
    'website',
    'documents',
  ])
)({
  ...TendersModel,
  defaultSortObject: [{ id: 'deadline', sortOrder: SortOrders.DESC }],
})

export const InfluenzaRevenueModel = compose<typeof RevenueModel>(
  whiteListColumn([
    'organisations',
    'financialYear',
    'quarter',
    'candidates',
    'designationA',
    'designationB',
    'valueUsd',
    'seasonalOrPandemic',
    'areas',
    'sources',
  ]),
  renameColumn('valueUsd', 'Revenue (USD)')
)({
  ...RevenueModel,
  defaultSortObject: [
    { id: 'financialYear', sortOrder: SortOrders.DESC },
    { id: 'quarter', sortOrder: SortOrders.DESC },
  ],
})
