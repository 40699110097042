import { Text } from '@chakra-ui/layout'

import VaccinesDetailView from 'routes/apps/vaccines/CandidateRanking'

import { ExtractModelDataUnion } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import {
  PressReleasesModel,
  MediaModel,
  ClinicalTrialsModel,
  PresentationsModel,
  CandidateRankingModel,
  CountriesModel,
  DealsModel,
  OrganisationsModel,
} from 'config/common/baseModel'
import { ApprovalsModel } from 'config/common/baseModel/Approvals'
import { customFilterNaNGraphData } from 'config/common/transformValue/charts'

import {
  compose,
  addOrOverrideColumn,
  whiteListColumn,
  renameColumn,
  updateColumnAttributes,
  blackListColumn,
} from 'utils/overrideModel'

const PhaseSortingObject = {
  sortOrderObject: [
    'null',
    'Validation',
    'Not Applicable',
    'Unknown',
    'Inactive',
    'Discontinued',
    'Paused',
    'Product Launch',
    'Approved',
    'Phase IV',
    'Preclinical',
    'Phase 0',
    'Phase I',
    'Phase I/II',
    'Phase II',
    'Phase II/III',
    'Phase III',
    'Phase III/IV',
  ],
}

const CandidatesBaseColumns: Array<
  ExtractModelDataUnion<typeof CandidateRankingModel>
> = [
  'vaccine',
  'alternativeNames',
  'type',
  'stage',
  'activeDevelopment',
  'storage',
  'antigenDiversity',
  'actionMechanism',
  'adjuvant',
  'deliveryMethod',
  'companies',
  'afEmergencyApproval',
  'afFullApproval',
  'targetVariant',
  'afPanCoronavirusVaccine',
]

const candidatesModifier = [
  renameColumn('vaccine', 'Name'),
  renameColumn('stage', 'Phase'),
  renameColumn('antigenDiversity', 'Target Domains'),
  renameColumn('deliveryMethod', 'Routes of Administration'),
  renameColumn('targetVariant', 'Target Strains'),
  blackListColumn([
    'afNumberOfDoses',
    'transportShipping',
    'storage',
    'afShelfLife',
    'afDoseQuantity',
  ]),
  updateColumnAttributes('stage', PhaseSortingObject),
]

export const VaccinesCandidatesModel = compose<typeof CandidateRankingModel>(
  whiteListColumn([
    ...CandidatesBaseColumns,
    'afDoseQuantity',
    'afPanSarsCov2Vaccine',
    'combinationVac',
  ]),
  updateColumnAttributes('vaccine', {
    type: ValueType.MULTI,
  }),
  ...candidatesModifier
)({
  ...CandidateRankingModel,
  name: 'Candidates',
  defaultSortObject: [
    {
      id: 'stage',
      sortOrder: SortOrders.DESC,
    },
    {
      id: 'vaccine',
      sortOrder: SortOrders.ASC,
    },
  ],
  renderDetailView: VaccinesDetailView,
})

export const VaccinesPreclinicalCandidates = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    ...CandidatesBaseColumns,
    'afPanSarsCov2Vaccine',
    'combinationVac',
  ]),
  ...candidatesModifier
)({
  ...CandidateRankingModel,
  name: 'Preclinical',
  defaultSortKey: 'stage',
  defaultSortOrder: SortOrders.ASC,
  renderDetailView: VaccinesDetailView,
})

export const VaccinesPhaseIOrIICandidates = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    ...CandidatesBaseColumns,
    'afPanSarsCov2Vaccine',
    'combinationVac',
  ]),
  ...candidatesModifier
)({
  ...CandidateRankingModel,
  name: 'Phase I or II',
  defaultSortKey: 'stage',
  defaultSortOrder: SortOrders.ASC,
  renderDetailView: VaccinesDetailView,
})

export const VaccinesPhaseIIIOrIVCandidates = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    ...CandidatesBaseColumns,
    'afDoseQuantity',
    'afPanSarsCov2Vaccine',
    'combinationVac',
  ]),
  ...candidatesModifier
)({
  ...CandidateRankingModel,
  name: 'Phase III or IV',
  defaultSortKey: 'stage',
  defaultSortOrder: SortOrders.ASC,
  renderDetailView: VaccinesDetailView,
})

export const CombinationVaccineCandidates = compose<
  typeof CandidateRankingModel
>(
  whiteListColumn([
    'vaccine',
    'alternativeNames',
    'type',
    'combinationVac',
    'stage',
    'latestPhaseManualDetails',
    'companies',
    'researchInstitutions',
    'deliveryMethod',
    'antigenDiversity',
    'actionMechanism',
  ]),
  renameColumn('combinationVac', 'Infectious Disease'),
  renameColumn('vaccine', 'Name'),
  renameColumn('stage', 'Phase'),
  renameColumn('latestPhaseManualDetails', 'Phase detail'),
  renameColumn('antigenDiversity', 'Target Domains'),
  renameColumn('deliveryMethod', 'Routes of Administration'),
  renameColumn('antigenDiversity', 'Target Domains'),
  renameColumn('researchInstitutions', 'Affiliated Institutions')
)({
  ...CandidateRankingModel,
  name: 'Combination Vaccine',
  defaultSortKey: 'stage',
  defaultSortOrder: SortOrders.ASC,
  renderDetailView: VaccinesDetailView,
})

export const DemoCountriesModel = compose<typeof CountriesModel>(
  whiteListColumn([
    'name',
    'afSupplyDealVaccine',
    'luEmergencyApproval',
    'luFullApproval',
    'afTotalVaccinations',
    'afTotalDeliveries',
    'afVaccinesDelivered',
    'afPeopleFullyVaccinated',
    'afBoostersAdministered',
  ])
)({ ...CountriesModel, defaultSortKey: 'uiCandidateCount' })

export const VaccinesCandidateRankingModel = whiteListColumn<
  typeof CandidateRankingModel
>([
  'vaccine',
  'type',
  'stage',
  'afNumberOfDoses',
  'transportShipping',
  'storage',
  'antigenDiversity',
  'afSumSupplyDealsAgreed',
  'afSumProduction',
  'countryFirstDetected',
  'actionMechanism',
  'deliveryMethod',
  'leads',
  'views',
  'downloads',
  'tweets',
  'preprintMentions',
  'deals',
  'numberOfArticles',
  'afFilingForApproval',
  'afFullApproval',
  'targetVariant',
  'afPanCoronavirusVaccine',
  'afShelfLife',
])({
  ...CandidateRankingModel,
  name: 'Vaccines',
})

export const VaccinesNeutralisingAntibodiesModel = whiteListColumn<
  typeof CandidateRankingModel
>([
  'linkAfTrUniqueTreatmentNameToBnf',
  'priority',
  'lookupAfTrClinicalTrialStageFromBnf',
  'lookupAfTrMechanismOfActionFromBnf',
  'lookupAfTrRouteOfAdministrationFromBnf',
  'afAntibodyType',
  'researchInstitutions',
  'companies',
  'downloads',
  'views',
  'preprintMentions',
  'numberOfArticles',
])({
  ...CandidateRankingModel,
})

export const VaccinesApprovalGuidelinesModel = whiteListColumn<
  typeof PresentationsModel
>([
  'countries',
  'title',
  'publicationDate',
  'topics',
  'language',
  'articleLink',
])({
  ...PresentationsModel,
  name: 'Vaccine Approval Guidelines',
})

export const VaccinesCandidatePapersModel = whiteListColumn<
  typeof PresentationsModel
>([
  'title',
  'journals',
  'publicationDate',
  'candidate',
  'topics',
  'abstract',
  'articleType',
  'journalImpactScore',
  'articleLink',
])({
  ...PresentationsModel,
  name: 'Publications',
  defaultSortOrder: SortOrders.DESC,
})

export const VaccinesCandidatePapersCorrelatesModel = compose<
  typeof PresentationsModel
>(
  whiteListColumn([
    'title',
    'journals',
    'publicationDate',
    'candidate',
    'topics',
    'abstract',
    'articleType',
    'journalImpactScore',
    'articleLink',
  ]),
  updateColumnAttributes('articleLink', {
    showTable: true,
  })
)({
  ...PresentationsModel,
  name: 'Correlates of Protection',
  defaultSortOrder: SortOrders.DESC,
})

export const VaccinesStrategyPapersModel = compose<typeof PresentationsModel>(
  whiteListColumn([
    'title',
    'publicationDate',
    'journals',
    'channel',
    'otherKeywords',
  ]),
  addOrOverrideColumn({
    key: 'title',
    width: 750,
  })
)({
  ...PresentationsModel,
  name: 'Strategy Papers',
  defaultSortOrder: SortOrders.DESC,
})

export const VaccinesNeutralisingAntibodiesScienceModel = whiteListColumn<
  typeof PresentationsModel
>([
  'title',
  'publicationDate',
  'journals',
  'channel',
  'topics',
  'authors',
  'articleType',
  'sentiment',
  'reviewStatus',
  'articleLink',
  'candidates',
  'abstract',
  'articleViews',
  'pdfDownloads',
  'journalImpactScore',
])({
  ...PresentationsModel,
  name: 'Neutralising Antibodies',
})

export const VaccinesBoosterTrialsModel = whiteListColumn<
  typeof ClinicalTrialsModel
>([
  'vaccine',
  'title',
  'acronym',
  'studyTypeClean',
  'enrolledAt',
  'completedAt',
  'sponsors',
  'url',
  'status',
  'ages',
  'locations',
  'outcomeMeasures',
  'primaryOutcome',
  'secondaryOutcome',
  'participants',
])({
  ...ClinicalTrialsModel,
  name: 'Trials',
  displayKey: 'vaccine',
})

export const VaccinesTrialsModel = compose<typeof ClinicalTrialsModel>(
  whiteListColumn([
    'govId',
    'title',
    'acronym',
    'vaccine',
    'comparatorVaccine',
    'sponsors',
    'phasesClean',
    'status',
    'afStartDate',
    'enrolledAt',
    'primaryCompletionDate',
    'completedAt',
    'targetEnrollmentText',
    'participants',
    'studyTypeClean',
    'ages',
    'primaryOutcome',
    'locations',
    'url',
  ]),
  renameColumn('vaccine', 'Candidate'),
  renameColumn('sponsors', 'Sponsors'),
  renameColumn('phasesClean', 'Phase')
)({
  ...ClinicalTrialsModel,
  displayKey: 'govId',
  searchField: 'vaccine',
  defaultSortKey: 'afDateAdded',
  defaultSortOrder: SortOrders.DESC,
})

export const VaccinesClinicalTrialsConfirmedMedia = whiteListColumn<
  typeof DealsModel
>([
  'lookupVaccineName',
  'afDealType',
  'countries',
  'afPhase3TrialSize',
  'mediaUrls',
  'pressUrls',
])({
  ...DealsModel,
})

export const VaccinesOrganisationsModel = whiteListColumn<
  typeof OrganisationsModel
>(['name', 'companyUrl', 'newsroomUrl'])(OrganisationsModel)

export const VaccinesCountriesModel = compose<typeof CountriesModel>(
  whiteListColumn([
    'name',
    'luInfectionRatePer10000',
    'afSupplyDealVaccine',
    'afWillingnessToTakeA_COVID19Vaccine',
    'luEmergencyApproval',
    'luFullApproval',
    'afTotalVaccinations',
    'afTotalDeliveries',
    'afVaccinesDelivered',
    'luCovaxH1Deliveries',
    'afPeopleVaccinatedOneDose',
    'afPeopleFullyVaccinated',
    'luSuspendedUseVaccineName',
    'fAged20Plus',
    'afTotalCasesB16172',
    'afPrevalenceB117',
    'afPrevalenceP1',
    'afPrevalenceB16172',
    'afPrevalenceB1351',
    'afPolicyForPreviouslyInfectedNotes',
    'afPolicyForPreviouslyInfectedLink',
    'afThirdDosePolicy',
    'afPolicyForThirdDosesUrl',
    'afBoostersAdministered',
    'visaguideVaccineInvalid',
    'visaguideVaccineValid',
    'afBoostersAdministeredPerHundred',
    'timeInterval',
    'boosterVaccineCandidate',
    'afStartDateForThirdDoses',
  ]),
  addOrOverrideColumn(
    {
      key: 'uiTrialToCandidateRatio',
      width: 240,
      type: ValueType.TEXT,
      label: '% of candidates in clinical trials',
      accessor: (d: any) =>
        d.uiTrialToCandidateRatio ? `${d.uiTrialToCandidateRatio}%` : null,
    },
    3
  )
)({ ...CountriesModel, defaultSortKey: 'uiCandidateCount' })

export const VaccinesSupplyModel = compose<typeof CountriesModel>(
  whiteListColumn([
    'name',
    'population',
    'afEconomyIncomeBand',
    'afContinent',
    'afSupplyDealVaccine',
    'sumAgreedSupply',
    'afSumPotentialExpansion',
    'afSumSupplyAndPotentialExpansion',
    'afTotalDeliveries',
    'fDeliveriesToDateExcThoseDonated',
    'fBilateralDeliveryToDate',
    'fCovaxDeliveryToDate',
    'fDirectDonationDeliveryToDate',
  ]),
  renameColumn('afEconomyIncomeBand', 'Income Band of Recipient Country'),
  renameColumn('afContinent', 'Continent of the Recipient Country'),
  renameColumn('afTotalDeliveries', 'Total Deliveries'),
  renameColumn(
    'fDirectDonationDeliveryToDate',
    'Doses Delivered from Bilateral Donations'
  ),
  renameColumn(
    'fCovaxDeliveryToDate',
    'Doses Delivered from COVAX (inc. donations and COVAX direct)'
  ),
  renameColumn(
    'fBilateralDeliveryToDate',
    'Doses delivered from Bilateral or Regional deals'
  ),
  renameColumn(
    'fDeliveriesToDateExcThoseDonated',
    'Doses Delivered exc. Doses Donated Out'
  ),
  renameColumn('afSupplyDealVaccine', 'Vaccines Secured'),
  renameColumn(
    'sumAgreedSupply',
    'Number of Vaccine Doses Secured via Bilateral and Regional Deals'
  ),
  renameColumn('afSumPotentialExpansion', 'Potential Expansion'),
  renameColumn(
    'afSumSupplyAndPotentialExpansion',
    'Number of Vaccine Doses Secured via Bilateral and Regional Deals (Inc. Potential Expansion)'
  )
)({ ...CountriesModel, name: 'Country Supply' })

export const VaccinesDealsModel = compose<typeof DealsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'afPricePerVaccineTreatment',
    'countries',
    'companyRegions',
    'mediaUrls',
    'pressUrls',
  ])
)({ ...DealsModel, name: 'Price' })
export const VaccinesDealsAndDonationsDealsModel = compose<typeof DealsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'organisations',
    'afDonorCountry',
    'countries',
    'companyRegions',
    'afSupplyNumber',
    'supplyContractedYear',
    'afSupplyCertainty',
    'afPublicSupplyNotes',
    'afPotentialExpansion',
    'potentialExpansionContractedYear',
    'afDeliveryToDate',
    'afDateDeliveryToDateWasLastUpdated',
    'afDeliveryCertainty',
    'afPublicDeliveryNotes',
    'luCountrySourceOfSupply',
    'afSourceOfSupplyCertainty',
    'afPurchaseType',
    'luIncomeBand',
    'lookupContinent',
    'afDonorIncomeBand',
    'afSupply2022',
    'targetVariant',
    'luCountryPopulation',
    'mediaUrls',
    'pressUrls',
    'twitterUrl',
  ]),
  renameColumn('countries', 'Recipient Country'),
  renameColumn('lookupContinent', 'Continent of the Recipient Country'),
  renameColumn('afSupplyNumber', 'Supply Number'),
  renameColumn('afPotentialExpansion', 'Potential Expansion'),
  renameColumn('afDeliveryToDate', 'Number of Doses Delivered to Date'),
  renameColumn('luCountryPopulation', 'Population of the Recipient Country'),
  renameColumn('afSupply2022', 'Description'),
  renameColumn('companyRegions', 'Regions that have secured these doses'),
  renameColumn(
    'afDateDeliveryToDateWasLastUpdated',
    'Date of the Latest Delivery'
  )
)({
  ...DealsModel,
  name: 'Supply',
  description: () => {
    return (
      <Text fontSize='sm' pb={3}>
        COVID-19 vaccine delivery disclaimer: Please note that all the donated
        doses have already been accounted for in donor's delivery to date. If
        looking for the sum of vaccines delivered by a vaccine candidate, please
        exclude all the records where donor country is not empty, to avoid
        double counting of vaccine doses. Countries donate vaccine doses both on
        a bilateral basis (stored under deal type 'Bilateral donations') and via
        COVAX (stored under deal type 'COVAX deliveries', where donor country is
        not empty)
      </Text>
    )
  },
})

export const VaccinesProductionDealsModel = compose<typeof DealsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'organisations',
    'dealCancelled',
    'countries',
    'afExpectedProduction',
    'afDateExpectedProductionLastAnnounced',
    'afPublicExpectedProductionNotes',
    'afTypeOfProduction',
    'afDealContracted',
    'mediaUrls',
    'pressUrls',
  ]),
  renameColumn('countries', 'Country source of production')
)({ ...DealsModel, name: 'Company Stated Production' })

export const VaccinesApprovalsDealsModel = compose<typeof ApprovalsModel>(
  whiteListColumn([
    'lookupVaccineName',
    'afDealType',
    'afAgeGroup',
    'approvalType',
    'countryGrantingDecision',
    'organisationsRegulators',
    'afDecisionDate',
    'associatedDocumentUrl',
  ])
)({
  ...ApprovalsModel,
  name: 'Vaccine Approvals',
  defaultSortKey: 'afDecisionDate',
})

export const VaccinesPressReleasesModel = compose<typeof PressReleasesModel>(
  addOrOverrideColumn<typeof PressReleasesModel>(
    {
      key: 'vaccine',
      type: ValueType.MULTI,
      label: 'Vaccines',
      width: 200,
      graphType: 'Pie',
      customDataFilter: (data) => customFilterNaNGraphData(data, 'vaccine'),
    },
    5
  ),
  whiteListColumn<typeof PressReleasesModel>([
    'title',
    'datePublished',
    'afTopic',
    'sourceUrl',
    'vaccine',
    'description',
    'companies',
    'afTags',
  ])
)(PressReleasesModel)

export const VaccinesMediaModel = compose<typeof MediaModel>(
  addOrOverrideColumn<typeof MediaModel>(
    {
      key: 'vaccine',
      type: ValueType.MULTI,
      label: 'Vaccines',
      width: 200,
      graphType: 'Pie',
      customDataFilter: (data) => customFilterNaNGraphData(data, 'vaccine'),
    },
    5
  ),
  whiteListColumn([
    'title',
    'source',
    'afTopic',
    'datePublished',
    'link',
    'vaccine',
    'name',
    'description',
    'language',
    'companyResearchInstitution',
    'afTags',
  ])
)(MediaModel)

export const CountriesDetailViewModal = compose<typeof CountriesModel>(
  blackListColumn([
    'luCovaxH1Deliveries',
    'afWillingnessToTakeA_COVID19Vaccine',
    'afTotalVaccinations',
    'afPeopleVaccinatedOneDose',
    'afPeopleFullyVaccinated',
    'fAged20Plus',
    'dailyTestsPerThousand',
    'afNewTests',
    'afTotalTests',
    'afTotalTestsPerThousand',
    'afNewTestsSmoothed',
    'afPositiveRate',
    'timeInterval',
    'afStartDateForThirdDoses',
    'afBoostersAdministered',
    'afBoostersAdministeredPerHundred',
    'afNewTestsPerThousand',
  ])
)(CountriesModel)
