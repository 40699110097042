import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

export type IDTreatments360DealsData =
  | 'id'
  | 'recipients'
  | 'recipientIncomeBand'
  | 'recipientRegion'
  | 'suppliers'
  | 'candidates'
  | 'isSupplyDeal'
  | 'categoryDetails'
  | 'procurementMechanism'
  | 'quantity'
  | 'quantityUnit'
  | 'quantityMethodologyType'
  | 'quantityMethodologyDescription'
  | 'pricePerUnitUsd'
  | 'pricePerUnitMethodologyType'
  | 'pricePerUnitMethodologyDescription'
  | 'dateStart'
  | 'dateEnd'
  | 'market'
  | 'description'
  | 'organisations'
  | 'diseaseName'
  | 'sources'
  | 'pathogenTypes'
  | 'designationA'
  | 'designationB'
  | 'yearDelivered'
  | 'categoryC'
  | 'status'
  | 'targetViralStrain'

const allowedAirtables = [
  'id_deals',
  'id_deal_price',
  'id_deal_supply',
  'flu_commercial_supply_deals',
  'flu_pricing_deals',
  'rsv_commercial_supply_and_price_deals_all',
  'rsv_commercial_supply_and_price_deals_vaccines',
  'rsv_commercial_supply_and_price_deals_treatments',
  'flu_commercial_supply_deals_seasonal',
  'flu_commercial_supply_deals_pandemic',
  'flu_pricing_deals_seasonal',
  'flu_pricing_deals_pandemic',
] as const

export const IDTreatmentPremiumDealsModel: IModel<
  Record<IDTreatments360DealsData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  displayKey: 'recipients',
  name: 'Regulatory',
  entityName: 'Regulatory',
  searchField: 'recipients',
  endpoint: 'deal',
  defaultSortKey: 'recipients',
  schema: {
    columns: [
      {
        key: 'pathogenTypes',
        label: 'Pathogen Types',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'recipients',
        label: 'Recipient',
        type: ValueType.MULTI,
        width: 200,
      },
      {
        key: 'recipientIncomeBand',
        label: 'Recipient Income Band',
        type: ValueType.TEXT,
        width: 180,
      },
      {
        key: 'recipientRegion',
        label: 'Recipient Region',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'suppliers',
        label: 'Suppliers',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'candidates',
        label: 'Candidates',
        type: ValueType.MULTI,
        width: 150,
      },
      {
        key: 'categoryDetails',
        label: 'Category',
        type: ValueType.SINGLE,
        width: 150,
      },
      {
        key: 'procurementMechanism',
        label: 'Procurement Mechanism',
        type: ValueType.SINGLE,
        width: 180,
      },
      {
        key: 'quantity',
        label: 'Quantity',
        type: ValueType.NUMBER,
        width: 100,
      },
      {
        key: 'quantityUnit',
        label: 'Quantity Unit',
        type: ValueType.TEXT,
        width: 150,
      },
      {
        key: 'quantityMethodologyType',
        label: 'Quantity Methodology',
        type: ValueType.TEXT,
        width: 200,
      },
      {
        key: 'quantityMethodologyDescription',
        label: 'Quantity Methodology Detail',
        type: ValueType.TEXT,
        width: 205,
      },
      {
        key: 'pricePerUnitUsd',
        label: 'Price per Unit (USD)',
        type: ValueType.NUMBER,
        width: 180,
      },
      {
        key: 'pricePerUnitMethodologyType',
        label: 'Price per Unit Methodology',
        type: ValueType.TEXT,
        width: 190,
      },
      {
        key: 'dateStart',
        label: 'Date Start',
        type: ValueType.DATE,
        width: 100,
      },
      {
        key: 'dateEnd',
        label: 'Date End',
        type: ValueType.DATE,
        width: 100,
      },
      {
        key: 'market',
        label: 'Market',
        type: ValueType.SINGLE,
        width: 100,
      },
      {
        key: 'description',
        label: 'Description',
        type: ValueType.TEXT,
        width: 250,
      },
      {
        key: 'diseaseName',
        label: 'Diseases',
        width: 180,
        type: ValueType.MULTI,
      },
      {
        key: 'sources',
        label: 'Source',
        width: 250,
        type: ValueType.URL,
      },
      {
        key: 'designationA',
        label: 'Vaccine or Treatment',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'designationB',
        label: 'Category',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'yearDelivered',
        label: 'Year',
        width: 100,
        type: ValueType.TEXT,
      },
      {
        key: 'pricePerUnitMethodologyDescription',
        label: 'Price per Unit Description',
        width: 280,
        type: ValueType.MULTI,
      },
      {
        key: 'categoryC',
        label: 'Deal Category',
        width: 250,
        type: ValueType.MULTI,
      },
      {
        key: 'status',
        label: 'Status',
        width: 165,
        type: ValueType.TEXT,
      },
      {
        key: 'targetViralStrain',
        label: 'Target Strains',
        width: 250,
        type: ValueType.MULTI,
      },
    ],
  },
}
