import { SelectOption } from 'components/Select'

import { IResponseBase } from 'api/types'

import { AppBase } from 'interfaces/appBase.interface'

import { IChart } from './charts.interface'
import { IEntity } from './entity.interface'
import {
  ExtractModelData,
  ExtractModelDataUnion,
  ExtractModelTables,
  IModel,
} from './model.interface'
import { IApp } from './navigationApp.interface'

export type DashboardConfigType<TColumns extends string> = {
  render:
    | React.FC<React.PropsWithChildren<DashboardConfigProps>>
    | Array<React.FC<React.PropsWithChildren<DashboardConfigProps>>>

  // what text should be shown in the vertical axis
  verticalAxisLabel?: string

  // what options should be included in the filters
  filterBlacklistOptions?: Array<TColumns>

  // what column and their values should not be separated
  allowValuesToBeJoinedWhitelist?: Array<TColumns>

  // if a chart needs to be summed, this is the key to do it by
  sumByKey?: TColumns

  // default filter values
  defaultHorizontalFilterValue?: SelectOption<TColumns>
  defaultStackByFilterValue?: SelectOption<TColumns>

  // should we show filters
  showStackBy?: boolean
  showTransformBy?: boolean

  // do we show the occurence threshold?
  showOccurenceThreshold?: boolean

  // should we show sort by select
  showSortBy?: boolean

  // should the legend be able to filter the table attached to it
  shouldLegendsFilterTable?: boolean

  // if using map, we need to provide a location key to count by
  locationKey?: TColumns

  // sorting orders for the column options
  sortingOrders?: Partial<Record<TColumns, Array<string>>>
}

export type DashboardConfigProps = {
  chartData: IResponseBase[]
  model: IModel<any, any>
  page: ISingleNavigationPage<any, any>
  loaded?: boolean
  isMultiChart?: boolean
  customLegendsRef: React.MutableRefObject<
    | {
        handleSpecificBarToggle: (
          id: string | number,
          index: string | number
        ) => void
      }
    | undefined
  >
  customLegendsRef1: React.MutableRefObject<
    | {
        handleSpecificBarToggle: (
          id: string | number,
          index: string | number
        ) => void
      }
    | undefined
  >
}

export type NavigationPageComponentProps<
  TData extends Object,
  TAirtables extends string = any
> = {
  model: IModel<TData>
  app: IApp
  page: ISingleNavigationPage<any, IModel<TData>>
  views: ViewList<TData, TAirtables>
  fields?: string[]
}

export type VisualisationPageComponentProps = {
  charts: IChart[]
  views?: ViewList<any>
  selectedView?: IBaseView<any>
  setView?: (newView: IBaseView<any>) => void
}

export interface ISingleNavigationPage<TSlugs, TModel extends IModel<any>> {
  /** Uniquely identify this page (scoped per app) */
  key: string
  path: TSlugs | TSlugs[] | null
  component: React.FC<
    React.PropsWithChildren<
      NavigationPageComponentProps<
        ExtractModelData<TModel>,
        ExtractModelTables<TModel>
      >
    >
  >
  entity?: IEntity
  charts?: IChart[]
  model: TModel
  views: ViewList<ExtractModelData<TModel>, ExtractModelTables<TModel>>
  // Flag to determine whether page should feature an auto generated dashboard section
  autoGenerateDashboard?: DashboardConfigType<ExtractModelDataUnion<TModel>>
}

export const defaultDashboardConfig: Partial<DashboardConfigType<any>> = {
  showTransformBy: true,
  showStackBy: true,
  shouldLegendsFilterTable: true,
  showSortBy: true,
  showOccurenceThreshold: true,
}

export type INavigationPages<TSlugs = string> = Record<
  string,
  ISingleNavigationPage<TSlugs, any>
>

export interface ISingleCustomPage<TSlugs> {
  path: TSlugs | TSlugs[] | null
  component: React.FC<React.PropsWithChildren<any>>
  entity?: IEntity
}

export interface IBaseView<
  TData extends Object,
  TAirtables extends string = any
> {
  name: string
  airtableName: TAirtables[number]
  overrideModel?: IModel<TData> | ((model: IModel<TData>) => IModel<TData>)
  airtableBase?: AppBase
}

// We'll use the first item as the default
export interface ViewList<TData extends Object, TAirtables extends string = any>
  extends Array<IBaseView<TData, TAirtables>> {
  // This enforces the array to have at least 1 item
  0: IBaseView<TData, TAirtables>
}

/** Context data based on view needed to render pages/call API */
export type ViewData = {
  airtableName: IBaseView<any>['airtableName']
  airtableBase?: IBaseView<any>['airtableBase']
}
