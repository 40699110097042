import '@fontsource/ibm-plex-sans'
import '@fontsource/ibm-plex-sans/300.css'
import '@fontsource/ibm-plex-sans/500.css'
import '@fontsource/ibm-plex-sans/600.css'
import '@fontsource/ibm-plex-sans/700.css'
import { QueryClientProvider, useIsFetching } from '@tanstack/react-query'
import { proxy } from 'comlink'
import { queryClient } from 'queryClient'
import { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AuthProvider } from 'react-oidc-context'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import instance from 'worker'

import AppRoutes from 'routes'
import AirfinitySecureRoute from 'routes/AirfinitySecureRoute'
import APISpec from 'routes/apiSpec'
import BaseRoute from 'routes/base'
import Bookmark from 'routes/bookmark'
import Settings from 'routes/settings'
import Tutorial from 'routes/tutorial'

import { AuthProvider as InternalAuthProvider } from 'contexts'
import { BookmarkProvider } from 'contexts/Bookmark'
import { ExportModalProvider } from 'contexts/ExportModal'
import { FilterModalProvider } from 'contexts/FilterModal'
import { GlobalSearchProvider } from 'contexts/GlobalSearch'
import { SidebarProvider } from 'contexts/Sidebar'
import { TableChartFiltersProvider } from 'contexts/TableChartFilters'

import { LoadingAnimation } from 'components'
import { registerInitialTableauMapping } from 'components/Embed/tableauSearchIndex'

import { userOrigin } from 'tracking'

import { appsList } from 'config/apps'

import GAFullStoryWrapper from './GAFullStoryWrapper'
import config from './config/oidc'
import TermsAndConditions from './routes/TermsAndConditions'

registerInitialTableauMapping()

const onSigninCallback = (_user): void => {
  const prevPath = window.sessionStorage.getItem('prevPath')
  if (prevPath) {
    window.sessionStorage.removeItem('prevPath')
    window.location.href = prevPath
  } else {
    window.location.href = '/'
  }
}

const MainRoute = () => {
  const [shouldRefreshOnNextPage, setShouldRefreshOnNextPage] = useState(false)
  const location = useLocation()

  const navigate = useNavigate()

  const isAbsoluteUrl = (url: string) => {
    return /^(?:[a-z]+:)?\/\//i.test(url)
  }

  const toRelativeUrl = (url = '', baseUrl: string) => {
    if (isAbsoluteUrl(url)) {
      url = url.substring(baseUrl.length)
    }
    return url?.charAt(0) === '/' ? url : `/${url}`
  }

  useEffect(() => {
    instance.onNeedUpdate(
      // If we need to update, let's wait until the user tries to change to another page
      proxy(() => {
        setShouldRefreshOnNextPage(true)
      })
    )

    instance.onReload(
      proxy(() => {
        window.location.reload()
      })
    )

    instance.onDataLoadProgress(
      proxy(() => {
        queryClient.invalidateQueries(['collectionDataRaw'])
      })
    )
    // Only run this on first load
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const origin = query.get('ref')
    const [_, app, page, subPage] = location.pathname.split('/')
    if (origin) userOrigin({ app, page, subPage, origin })
    if (shouldRefreshOnNextPage) {
      window.location.reload()
    }
    // Only run this when location changes
    // eslint-disable-next-line
  }, [location])

  return (
    <AuthProvider {...config.config} onSigninCallback={onSigninCallback}>
      <QueryClientProvider client={queryClient}>
        <InternalAuthProvider>
          <BookmarkProvider>
            <DndProvider backend={HTML5Backend}>
              <FilterModalProvider>
                <ExportModalProvider>
                  <TableChartFiltersProvider>
                    <GlobalSearchProvider>
                      <SidebarProvider>
                        <CypressTestHandler />
                        <GAFullStoryWrapper />
                        <Routes>
                          <Route
                            path='/'
                            element={
                              <AirfinitySecureRoute>
                                <BaseRoute />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path='/implicit/callback'
                            element={
                              <AirfinitySecureRoute>
                                <LoadingAnimation />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path='/terms-and-conditions'
                            element={
                              <AirfinitySecureRoute>
                                <TermsAndConditions />
                              </AirfinitySecureRoute>
                            }
                          />
                          {appsList.map((appSlug) => (
                            <Route
                              key={appSlug}
                              path={`/${appSlug}/*`}
                              element={
                                <AirfinitySecureRoute>
                                  <AppRoutes />
                                </AirfinitySecureRoute>
                              }
                            >
                              <Route path=':pageSlug'>
                                <Route path=':subPageSlug' />
                              </Route>
                            </Route>
                          ))}
                          <Route
                            path={`/settings/*`}
                            element={
                              <AirfinitySecureRoute>
                                <Settings />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path={`/api-spec-forecast`}
                            element={
                              <AirfinitySecureRoute>
                                <APISpec product={'forecast'} />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path={`/api-spec-epid-id`}
                            element={
                              <AirfinitySecureRoute>
                                <APISpec product={'epid-id'} />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path={`/api-spec-outbreak`}
                            element={
                              <AirfinitySecureRoute>
                                <APISpec product={'outbreak'} />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path={'api-spec-report'}
                            element={
                              <AirfinitySecureRoute>
                                <APISpec product={'report'} />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path={`/tutorial/*`}
                            element={
                              <AirfinitySecureRoute>
                                <Tutorial />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path={`/bookmarks/*`}
                            element={
                              <AirfinitySecureRoute>
                                <Bookmark />
                              </AirfinitySecureRoute>
                            }
                          />
                          <Route
                            path='*'
                            element={<Navigate to='/' replace />}
                          />
                        </Routes>
                      </SidebarProvider>
                    </GlobalSearchProvider>
                  </TableChartFiltersProvider>
                </ExportModalProvider>
              </FilterModalProvider>
            </DndProvider>
          </BookmarkProvider>
        </InternalAuthProvider>
      </QueryClientProvider>
    </AuthProvider>
  )
}

const CypressTestHandler = () => {
  const isFetching = useIsFetching()

  if (!isFetching) {
    return <div data-cy='web-xhr-not-fetching' />
  }

  return null
}
const App = () => (
  <Router>
    <MainRoute />
  </Router>
)

export default App
