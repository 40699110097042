import { IModel } from 'interfaces/model.interface'
import { ValueType } from 'interfaces/valueType.interface'

import { SortOrders } from 'enums/SortOrders'

import AgeGroupOther from 'config/common/tagColours/AgeGroupOther'
import EligibleAgeGroup from 'config/common/tagColours/EligibleAgeGroup'
import floatToPercentageFormat from 'config/common/transformValue/percentage'

export type RespiratoryImmunisationScheduleData =
  | 'linkCountry'
  | 'influenzaSeason'
  | 'immunisationScheduleExpanded'
  | 'eligibleAgeGroups'
  | 'eligibleGroupsOther'
  | 'percentageOfPopulationEligibleForVaccination'
  | 'percentageIncreaseInEligiblePopulationFrom2019To20'
  | 'newlyEligible'
  | 'areas'
  | 'candidates'
  | 'targetPopulationDescription'
  | 'targetPopulationDetailed'
  | 'eligiblePopulation'
  | 'sourceComment'
  | 'sources'
  | 'announcementDate'

const allowedAirtables = [
  'front_end_export',
  'COVID-19 Therapeutics Commercial Eligible Population',
] as const

export const RespiratoryImmunisationScheduleModel: IModel<
  Record<RespiratoryImmunisationScheduleData, string>,
  typeof allowedAirtables
> = {
  allowedAirtables,
  name: 'Immunisation Schedule',
  entityName: 'Immunisation Schedules',
  endpoint: 'immunisation-schedule',
  detailViewType: 'Generic',
  displayKey: 'linkCountry',
  searchField: 'linkCountry',
  defaultSortObject: [
    {
      id: 'percentageOfPopulationEligibleForVaccination',
      sortOrder: SortOrders.DESC,
    },
  ],
  excludeGeneratedCharts: true,
  schema: {
    columns: [
      {
        key: 'linkCountry',
        label: 'Country',
        width: 160,
        type: ValueType.MULTI,
      },
      {
        key: 'influenzaSeason',
        label: 'Influenza Season',
        width: 140,
        type: ValueType.MULTI,
        cellFormat: {
          colours: {
            '2019-20': 'rgb(207, 223, 255)',
            '2020-21': 'rgb(208, 240, 253)',
            '2021-22': 'rgb(194, 245, 233)',
          },
        },
      },
      {
        key: 'immunisationScheduleExpanded',
        label: 'Immunisation Schedule Expanded',
        width: 200,
        type: ValueType.SINGLE,
        cellFormat: {
          colours: {
            Yes: 'rgb(147, 224, 136)',
            No: 'rgb(248, 43, 96)',
            'N/A': 'rgb(238, 238, 238)',
          },
        },
      },
      {
        key: 'eligibleAgeGroups',
        label: 'Eligible Age Groups',
        width: 300,
        type: ValueType.MULTI,
        cellFormat: {
          colours: EligibleAgeGroup,
        },
      },
      {
        key: 'eligibleGroupsOther',
        label: 'Eligible Groups (Other)',
        width: 450,
        type: ValueType.MULTI,
        cellFormat: {
          colours: AgeGroupOther,
        },
      },
      {
        key: 'percentageOfPopulationEligibleForVaccination',
        label: 'Percentage of Population Eligible For Vaccination',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: floatToPercentageFormat,
      },
      {
        key: 'percentageIncreaseInEligiblePopulationFrom2019To20',
        label: 'Percentage Increase in Eligible Population (From 2019-20)',
        width: 200,
        type: ValueType.NUMBER,
        transformValue: floatToPercentageFormat,
      },
      {
        key: 'newlyEligible',
        label: 'Newly Eligible',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'areas',
        label: 'Country or Region',
        width: 150,
        type: ValueType.MULTI,
      },
      {
        key: 'candidates',
        label: 'Candidate',
        width: 200,
        type: ValueType.MULTI,
      },
      {
        key: 'targetPopulationDescription',
        label: 'Criteria Type',
        width: 180,
        type: ValueType.TEXT,
      },
      {
        key: 'targetPopulationDetailed',
        label: 'Specific Criteria',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'eligiblePopulation',
        label: 'Eligible Population',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'sourceComment',
        label: 'Notes',
        width: 200,
        type: ValueType.TEXT,
      },
      {
        key: 'sources',
        label: 'Source',
        width: 320,
        type: ValueType.URL,
      },
      {
        key: 'announcementDate',
        label: 'Announcement Date',
        width: 180,
        type: ValueType.DATE,
      },
    ],
  },
}
